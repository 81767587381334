<template>
  <div class="form-popup" @click="clos()">
    <div class="form-container" @click.stop="">
      <div class="error-popup" v-show="incompleteData">
        <i class="ik ik-alert-triangle"></i>
        <span>
          {{ customErrorMsg }}
        </span>
      </div>
      <div class="form-header">
        <span>Ajouter une caution </span>
        <i class="ik ik-x clos" @click="clos()"></i>
      </div>
      <div class="form-main">
        <div class="input-container">
          <div class="input-item">
            <span>Montant*</span>
            <input
              type="number"
              min="0"
              placeholder="Entrez le montant de la caution"
              v-model="caution.montant"
            />
          </div>
          <div class="input-item">
            <span>Date</span>
            <DatePicker
              v-model="caution.date"
              placeholder="Selectionnez une date"
              format="dddd, DD MMMM YYYY"
              valueType="YYYY-MM-DD"
              class="w-100"
              :clearable="false"
            />
          </div>
          <div class="input-item">
            <span>Type de caution*</span>
            <select v-model="caution.type">
              <option
                v-for="(typ, cle) in typesCautions"
                :key="cle"
                :value="typ.libelle"
              >
                {{ typ.libelle }}
              </option>
            </select>
          </div>
          <div class="input-item">
            <span>Etat caution*</span>
            <select v-model="caution.etat">
              <option
                v-for="(ett, cle) in etatsCautions"
                :key="cle"
                :value="ett.libelle"
              >
                {{ ett.libelle }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-footer">
        <div class="cancel" @click="clos()">
          <OutlinedDarkBtn buttonTitle="Annuler" />
        </div>
        <div class="validate" @click="submitModal()">
          <FullPinkBtn buttonTitle="Ajouter la caution" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FullPinkBtn from "../atomic/fullPinkBtn.vue";
import OutlinedDarkBtn from "../atomic/outlinedDarkBtn.vue";
import DatePicker from "vue2-datepicker";
const php = require("phpjs");

export default {
  props: {
    idOccupation: { String, required: true },
  },
  components: {
    FullPinkBtn,
    OutlinedDarkBtn,
    DatePicker,
  },
  data: () => ({
    caution:{
        type:'loyer',
        etat:'non reglée',
        date:null,
        montant:0,
    },
    incompleteData: false,
    customErrorMsg: "Une erreur est survenue",
    typesCautions: [
      {
        libelle: "eau",
      },
      {
        libelle: "électricité",
      },
      {
        libelle: "loyer",
      },
    ],
    etatsCautions: [
      {
        libelle: "réglée",
      },
      {
        libelle: "non reglée",
      },
      {
        libelle: "remboursée",
      },
    ],
  }),
  computed: {
    ...mapGetters(["user", "hasAccess"]),
    canGenerateCharges() {
      return this.hasAccess("generate_charge_occupation");
    },
  },
  methods: {
    clos() {
      this.$emit("close");
    },
    submitModal() {
      if (php.empty(this.caution.date)) {
        this.customErrorMsg = 'Veuillez renseigner la date';
            this.incompleteData = true;
            setTimeout(() => {
              this.incompleteData = false;
            }, 2000);
      }
      if (this.caution.montant == "") {
        this.customErrorMsg = 'Vous devez spécifier un montant';
            this.incompleteData = true;
            setTimeout(() => {
              this.incompleteData = false;
            }, 2000);
        // return App.error("Vous devez spécifier un montant");
      }
      if (this.caution.type == "") {
        // return App.error(
        //   "Vous devez spécifier selectionner le type de caution dont il s'agit"
        // );
        this.customErrorMsg = 'Choisir un type de caution';
            this.incompleteData = true;
            setTimeout(() => {
              this.incompleteData = false;
            }, 2000);
      }
      axios
        .post(
          "occupations/" + this.idOccupation + "/cautions",
          this.caution
        )
        .then((response) => {
          this.$emit("caution-added", response.result);
          return App.notifySuccess(response.message);
        })
        .catch((error) => {
        //   notif.error(error.message);
          this.customErrorMsg = error.message;
            this.incompleteData = true;
            setTimeout(() => {
              this.incompleteData = false;
            }, 2000);
        });
      //   if (this.action == "add") {
      //     console.log('caution', this.caution)
      //       this.showOverlay = true;
      //         axios.post('occupations/'+this.occupation.idOccupation+'/cautions',this.caution).then(response =>{
      //               this.resetModal();
      //                this.showOverlay = false;
      //               this.$emit("caution-added", response.result);
      //               return App.notifySuccess(response.message)

      //         })
      //         .catch(error => {
      //             this.showOverlay = false;
      //              notif.error(error.message);
      //         });

      //   }
      //   if (this.action == "edit") {
      //       this.showOverlay = true;
      //         axios.put('cautions/'+this.cautionToEdit.idCaution,this.caution).then(response =>{
      //               this.resetModal();
      //                this.showOverlay = false;
      //               this.$emit("caution-edited", response.result);
      //               return App.notifySuccess(response.message)

      //         })
      //         .catch(error => {
      //             this.showOverlay = false;
      //              notif.error(error.message);
      //         });

      //   }
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.form-popup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  animation: appear2 0.35s;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  z-index: 1500;
  cursor: pointer;
}
@keyframes appear2 {
  0% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.form-popup .form-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 70%;
  max-height: 650px;
  width: 350px;
  max-width: 500px;
  background: white;
  border-radius: 8px;
  cursor: auto;
}
.form-container .form-header {
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #eee;
}
.form-header span {
  font-weight: 600;
  font-size: 1.3rem;
}
.form-header .clos {
  font-size: 1.4rem;
  color: #191c22;
  cursor: pointer;
}
.form-container .form-footer {
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  border-top: 1px solid #eee;
}
.form-container .form-main {
  padding: 5%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 150px);
}
.form-container .error-popup {
  padding-right: 10px;
  position: absolute;
  bottom: 100px;
  left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 90%;
  border-radius: 8px;
  background: white;
  animation: appear 0.3s;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.12);
}
.error-popup i {
  margin: 0 15px;
  font-size: 1.5rem;
}
.error-popup span {
  text-align: left;
  font-size: 0.9rem;
  font-weight: 500;
}
@keyframes appear {
  0% {
    transform: translateY(30px);
    box-shadow: none;
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.12);
  }
}
.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.form-main span {
  font-size: 1.1rem;
  margin-bottom: 10px;
}
.input-container .input-item {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}
.input-item > span {
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 600;
}
.input-item input,
.input-item select {
  padding: 0 5px;
  height: 45px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
  outline: none;
  background: transparent;
}
.input-item select option {
  padding: 10px 0;
  /* height: 45px; */
}
.validate {
  width: calc(60% - 5px);
}
.cancel {
  width: calc(40% - 5px);
}
</style>