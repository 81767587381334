<template>
    <div>
        <b-row>
            <b-col>
                <b-form-group :label="$t('data.occupation_compte_a_recharger')">
                    <b-form-select v-model="modal.idCompte" :options="comptes" value-field="idCompte" text-field="typeCompte" />
                </b-form-group>
            </b-col>
            <b-col>
                <div>
                    <label style="margin-top:-2em;">{{$t('data.occupation_montant_de_la_recharge')}}<span style="color: red;font-size: 1.5em;">*</span></label>
                    <vue-numeric class="form-control" :placeholder="$t('data.occupation_montant_de_la_recharge_exemple')" required separator=" " v-model="modal.montant"></vue-numeric>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group label="Observation">
                    <b-form-textarea placeholder="Vous pouvez ajouter une observation pour cette recharge" v-model="modal.description" rows="7" max-rows="8" />
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group :label="$t('data.cite_label_photo')">
                    <img-inputer v-model="modal.photoRecu" :img-src="$getBase64(modal.photoRecu, false)" placeholder="preuve de paiement" theme="light" size="sm" bottom-text="joindre un recu de paiement" icon="img"  />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group label="Date">
                    <date-picker v-model="modal.dateDepot" placeholder="Quand est-ce que vous avez perçu l'argent" format="dddd, DD MMMM YYYY" valueType="YYYY-MM-DD" class="w-100" :clearable="false" />
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="">
                    <b-form-checkbox class="mt-4 d-flex justify-content-center align-items-center" v-model="modal.payerCharge">Payer les charges</b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-row>
        
        
        <b-button class="float-right mt-2" variant="danger" :disabled="submitted" @click.prevent="runPaiement">{{$t('data.cite_valider_cite')}}  <b-spinner v-if="submitted" small /></b-button>
    </div>
</template>

<script>
const php = require('phpjs');

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/fr';
import Vue from 'vue'
import VueNumeric from 'vue-numeric'

Vue.use(VueNumeric)

export default {
    props: {
        comptes: {type: Array, required: true},
    },
    components: {
        DatePicker
    },
    data: () => ({
        amount: '',
        submitted: false,
        modal: {
            dateDepot:'', description:'', montant:'', idCompte:'', payerCharge:false,
             photoRecu: ''
        },
        /*dateDepot: null,
        description: null,
        photoRecu:'',
        montant: null,
        idCompte: null,
        payerCharge: false,*/
    }),
    mounted() {
        this.modal.idCompte = this.comptes.find(elt => elt.typeCompte.toLowerCase() == 'principal').idCompte || null
        this.modal.dateDepot = this.$dayjs().format('YYYY-MM-DD HH:mm')
    },
    methods: {
        async runPaiement() {
            if (php.empty(this.modal.idCompte) || php.empty(this.modal.montant)) {
                return App.error('Veuillez entrer le montant de la recharge et le compte à recharger')
            }
            const data = {
                dateDepot: this.dateDepot,
                description: this.description,
                montant: this.montant,
                idCompte: this.idCompte,
                payerCharge: this.payerCharge,
                recu: await this.$getBase64(this.photRecu)
            }
            this.submitted = true
           
            axios.post('comptes/' + this.modal.idCompte + '/recharge', this.modal).then(response => {
                this.submitted = false 
                this.$emit('payed')
                return App.notifySuccess(response.message)
            }).catch(error => {
                this.submitted = false
                return App.alertError(error.message)
            })
        },
    }
}
</script>