<template>
  <button>
    <div v-if="loading" class="loader"></div>
    <div v-else="loading">
      {{buttonTitle}}
    </div>
  </button>
</template>

<script>
export default {
    props:{
        buttonTitle: {type: String, required: true, default: "Valider",},
        loading: {type: Boolean , required: true, default: false}
    },
    methods:{
        click(){
            this.$emit('click');
        }
    }
};
</script>

<style scoped>
button {
  padding: 0px 35px;
  height: 45px;
  border-radius: 4px;
  font-weight: bold;
  color: #191c22;
  font-weight: bold;
  color: white;
  background: #f5365c;
  border: none;
  width: 100%;
  transition: 0.2s ease all;
}
button:hover {
  transform: scale(1.02);
}
.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 10px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
.colors2 {
  border-bottom: 16px solid transparent;
}
.colors3 {
  border-bottom: 16px solid red;
  border-right: 16px solid green;
}
.colors4 {
  border-bottom: 16px solid red;
  border-right: 16px solid green;
  border-left: 16px solid pink;
}
</style>
