<template>
  <button>
    {{buttonTitle}}
  </button>
</template>

<script>
export default {
    props:{
        buttonTitle: {type: String, required: true, default: "Fermer",}
    },
    methods:{
        click(){
            this.$emit('click');
        }
    }
};
</script>

<style scoped>
button {
  padding: 0px 35px;
  height: 45px;
  border-radius: 4px;
  font-weight: bold;
  color: #191c22;
  border: 0.5px solid #191c22;
  background: transparent;
  width: 100%;
  transition: 0.2s ease all;
}
button:hover {
  transform: scale(1.02);
}
</style>
