<template>
    <div>
        <b-form-group :label="$t('data.occupation_payer_charge_montant')" :description="$t('data.occupation_payer_charge_description')">
            <b-form-input type="number" min="1" :placeholder="$t('data.occupation_payer_charge_description_exemple')" v-model="amount" />
        </b-form-group>
        <b-button class="float-right mt-2" variant="danger" :disabled="submitted" @click.prevent="runPaiement">{{$t('data.cite_valider_cite')}}  <b-spinner v-if="submitted" small /></b-button>
    </div>
</template>

<script>
const php = require('phpjs');

export default {
    props: {
        idCharge: {type: "number", required: true},
        montant: {type: "number", required: true}
    },
    data: () => ({
        amount: null,
        submitted: false,
    }),
    mounted() {
        this.amount = JSON.parse(JSON.stringify(this.montant))
    },
    methods: {
        runPaiement() {
            if (php.empty(this.amount)) {
                return App.error('Veuillez entrer le montant à payer')
            }
            this.submitted = true 
            axios.put('charges/' + this.idCharge + '/buy', {montant: this.amount}).then(async(response) => {
                this.submitted = false 
                this.$emit('payed')
                return App.notifySuccess(response.message)
            }).catch(error => {
                this.submitted = false 
                return App.alertError(error.message)
            })
        },
    }
}
</script>