<template>
  <div class="create-cite" @click="close()">
    <div class="cite-form" @click.stop="">
      <div class="error-popup" v-show="incompleteData">
        <i class="ik ik-alert-triangle"></i>
        <span>
          {{ customErrorMsg }}
        </span>
      </div>
      <div class="header-form">
        <span> Recharger le compte </span>
        <div class="close-icon">
          <i class="ik ik-x clos" @click="close()"></i>
        </div>
      </div>
      <div class="main-form">
        <div class="main-left">
          <div class="input-item">
            <span>Montant de la recharge</span>
            <input
              type="number"
              placeholder="Ex: 5000"
              v-model="modal.montant"
            />
          </div>
          <div class="input-item">
            <span>Date</span>
            <DatePicker
              placeholder="Selectionnez une date"
              format="dddd, DD MMMM YYYY"
              valueType="YYYY-MM-DD"
              class="w-100"
              :clearable="false"
              v-model="modal.dateDepot"
            />
          </div>
          <div class="input-item">
            <span>Description</span>
            <input
              type="text"
              placeholder="Un commentaire, une note..."
              v-model="modal.description"
            />
          </div>
          <span>
            <input type="checkbox" v-model="modal.payerCharge" />
            Payer les charges
          </span>
        </div>
        <div class="main-right">
          <div class="input-item">
            <span>Photos (preuve de paiement)</span>
            <div class="img-block">
              <label>
                <input
                  type="file"
                  accept="image/*"
                  ref="imageCite"
                  @change="onFile"
                />
              </label>
              <img
                :src="modal.photoRecu"
                v-if="modal.photoRecu"
                alt="image de la cité"
              />
              <div class="placeholder-img" v-else>
                <i class="ik ik-image"></i>
                <span> Veuillez choisir une image. </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-form">
        <div class="cancel" @click="close()">
          <OutlinedDarkBtn buttonTitle="Annuler" />
        </div>
        <div class="validate" @click="runPaiement()">
          <!-- <b-spinner small /> -->
          <FullPinkBtn buttonTitle="Recharger le compte" :loading=loading></FullPinkBtn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const php = require("phpjs");
import FullPinkBtn from "../atomic/fullPinkBtn.vue";
import OutlinedDarkBtn from "../atomic/outlinedDarkBtn.vue";
import DatePicker from "vue2-datepicker";
export default {
  components: { FullPinkBtn, OutlinedDarkBtn, DatePicker },

  props: {
    comptes: { type: Array, required: true },
  },
  data() {
    return {
      photo: "",
      modal: {
        dateDepot: "",
        description: "",
        montant: "",
        idCompte: "",
        payerCharge: false,
        photoRecu: "",
      },
      incompleteData: false,
      customErrorMsg: `Données incomplètes....`,
      loading: false,
    };
  },
  methods: {
    onFile(e) {
      const files = e.target.files;
      if (!files.length) return;
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => (this.modal.photoRecu = reader.result);
      console.log(this.modal.photoRecu);
    },
    close() {
      this.$emit("close");
    },
    async runPaiement() {
      this.loading = true;
      this.modal.idCompte =
        this.comptes.find((elt) => elt.typeCompte.toLowerCase() == "principal")
          .idCompte || null;
      //this.modal.dateDepot = this.$dayjs().format("YYYY-MM-DD HH:mm");
      console.log(this.modal.montant);
      console.log(php.empty(this.modal.montant));
      if (php.empty(this.modal.montant)) {
        this.customErrorMsg = "Données incomplètes";
        this.incompleteData = true;
        setTimeout(() => {
          this.incompleteData = false;
        }, 2000);
        // return App.error(
        //   "Veuillez entrer le montant de la recharge et le compte à recharger"
        // );
      }
      const data = {
        dateDepot: this.dateDepot,
        description: this.description,
        montant: this.montant,
        idCompte: this.idCompte,
        payerCharge: this.payerCharge,
        recu: await this.$getBase64(this.photRecu),
      };
      this.submitted = true;

      axios
        .post("comptes/" + this.modal.idCompte + "/recharge", this.modal)
        .then((response) => {
          this.submitted = false;
          this.$emit("payed");
          this.close();
          this.loading = false;
          return App.notifySuccess(response.message);
        })
        .catch((error) => {
          this.submitted = false;
          this.loading = false;
          this.customErrorMsg = error.message;
          console.log(this.customErrorMsg);
          this.incompleteData = true;
          setTimeout(() => {
            this.incompleteData = false;
          }, 2000);
          // return App.alertError(error.message);
        });
    },
  },
  mounted() {
    let googleScript = document.createElement("script");
    googleScript.setAttribute(
      "src",
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyB4wnGV-GeVMiv-_yT2qO_iKyaM_bMGX7w&libraries=places"
    );
    document.head.appendChild(googleScript);
  },
};
</script>

<style scoped>
.create-cite {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  animation: appear2 0.35s;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  z-index: 1500;
  cursor: pointer;
}
@keyframes appear2 {
  0% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.error-popup {
  padding-right: 10px;
  position: absolute;
  bottom: 30px;
  left: calc(50% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 350px;
  border-radius: 8px;
  background: white;
  animation: appear 0.3s;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.12);
  z-index: 10000;
}
.error-popup i {
  margin: 0 15px;
  font-size: 1.5rem;
}
.error-popup span {
  text-align: left;
  font-size: 0.9rem;
  font-weight: 500;
}
@keyframes appear {
  0% {
    transform: translateY(30px);
    box-shadow: none;
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.12);
  }
}
.create-cite .cite-form {
  position: relative;
  height: auto;
  width: 50%;
  max-height: 700px;
  background: white;
  border-radius: 8px;
  cursor: auto;
}
.cite-form .header-form {
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10%;
  min-height: 70px;
  border-bottom: 1px solid #eee;
}
.header-form span {
  font-size: 1.4rem;
  font-weight: bold;
}
.header-form .close-icon {
  font-size: 1.5rem;
  color: #191c22;
  cursor: pointer;
}
.cite-form .main-form {
  padding: 30px 5%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5%;
  width: 100%;
  height: auto;
}
.main-form .main-left {
  grid-area: 1 / 1 / 2 / 2;
}
.main-form .main-right {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  flex-direction: column;
}
.main-form .input-item {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}
.input-item > span {
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 500;
}
.input-item input:not([type="image"]) {
  padding: 0 5px;
  height: 45px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
  outline: none;
  background: transparent;
}
.input-item input::placeholder {
  font-size: 0.85rem;
}
.input-item .img-block {
  margin-top: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-item .img-block img {
  display: inline-block;
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.input-item .img-block label {
  position: absolute;
  top: 0;
  left: 0;
  height: 250px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
}
.input-item .img-block label input {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: transparent;
  display: none;
}
.input-item .img-block .placeholder-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 100%;
}
.placeholder-img i {
  font-size: 1.8rem;
}
.cite-form .footer-form {
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10%;
  min-height: 70px;
  border-top: 1px solid #eee;
}
</style>
