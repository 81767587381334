<template>
  <div>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          data-toggle="tab"
          href="#"
          class="nav-link"
          :class="{ active: section == 'caracteristiques' }"
          @click.prevent="section = 'caracteristiques'"
          >{{$t('data.logement_detail_caracteristiques')}}</a
        >
      </li>
      <li v-if="canViewListAnnoncesLogement" class="nav-item">
        <a
          data-toggle="tab"
          href="#"
          class="nav-link"
          :class="{ active: section == 'annonces' }"
          @click.prevent="section = 'annonces'"
          >{{$t('data.logement_detail_annonces')}}</a
        >
      </li>
      <li v-if="canViewListDepensesLogement" class="nav-item">
        <a
          data-toggle="tab"
          href="#"
          class="nav-link"
          :class="{ active: section == 'depenses' || section == 'add-depense' }"
          @click.prevent="section = 'depenses'"
          >{{$t('data.logement_detail_depenses')}}</a
        >
      </li>
      <li v-if="canViewLocatairesLogement" class="nav-item">
        <a
          data-toggle="tab"
          href="#"
          class="nav-link"
          :class="{ active: section == 'locataire' }"
          @click.prevent="section = 'locataire'"
          >{{$t('data.logement_detail_locataires')}}</a
        >
      </li>
    </ul>
    <div class="tab-content mt-3">
      <div class="tab-pane fade show active">
        <b-container fluid v-if="section == 'caracteristiques'">
          <h4 class="border-bottom mb-2">Aperçu</h4>
          <b-row>
            <b-col cols="12" lg="6" class="text-dark">
              <dl class="row" >
                  <dd class="mt-1 col-5">Reference</dd>
                  <dt class="mt-1 col-7 font-weight-bold fa-lg">{{ logement.refLogement }}</dt>
                  <dd class="mt-1 col-5">{{ $t('data.logement_detail_type_logement')}}</dd>
                  <dt class="mt-1 col-7 font-weight-bold fa-lg" style="line-height: 1.5em">{{ logement.sousTypeLogement.libelleSousType }}</dt>
                  
                  <dd class="mt-1 col-5" v-if="logement.batiment">{{$t('data.occupation_batiment')}}</dd>
                  <dt class="mt-1 col-7 font-weight-bold fa-lg" style="line-height: 1.5em" v-if="logement.batiment">{{ logement.batiment.nomBatiment }}</dt>
                  <dd class="mt-1 col-5" v-if="logement.batiment">{{ $t('data.occupation_cite') }}</dd>
                  <dt class="mt-1 col-7 font-weight-bold fa-lg" style="line-height: 1.5em" v-if="logement.batiment">{{ logement.batiment.cite.nomCite }}</dt>
              </dl>
            </b-col>
            <b-col cols="12" lg="6" class="text-dark">
              <dl class="row" >
                  <dd class="mt-1 col-5">{{$t('data.logement_detail_prix_min_logement')}}</dd>
                  <!--<dt class="mt-1 col-7 font-weight-bold fa-lg">{{ logement.prixMin + ' F' }}</dt>-->
                  <dt class="mt-1 col-7 font-weight-bold fa-lg">{{ logement.prixMin | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</dt>
                  <dd class="mt-1 col-5">{{$t('data.logement_detail_prix_max_logement')}}</dd>
                  <!--<dt class="mt-1 col-7 font-weight-bold fa-lg">{{ logement.prixMax + 'F' }}</dt>-->
                  <dt class="mt-1 col-7 font-weight-bold fa-lg">{{ logement.prixMax | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</dt>
                  
                  <dd class="mt-1 col-5">{{$t('data.logement_detail_superficie')}}</dd>
                  <dt class="mt-1 col-7 font-weight-bold fa-lg">{{ 32 | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }} m<sup>2</sup></dt>
                  
                  <dd class="mt-1 col-5">{{$t('data.logement_detail_titre_etat_logement')}}</dd>
                  <dt class="mt-1 col-7">
                    <b-badge variant="success" v-if="logement.etatLogement"><span class="font-weight-bold fa-lg">{{$t('data.logement_etat_occupe')}}</span></b-badge>
                    <b-badge variant="danger" v-else><span class="font-weight-bold fa-lg">{{$t('data.logement_etat_libre')}}</span></b-badge>
                  </dt>
                </dl>
            </b-col>
          </b-row>

          <h4 class="border-bottom mb-2 mt-4">{{$t('data.logement_detail_caracteristiques')}}</h4>
          <div>
            <b-badge variant="light" class="m-1 border" v-for="(c, i) in caracteristiques" :key="c.idCaracteristique || i">
              <span class="fa-lg"><b class="text-danger">{{ c.valeur }}</b> {{ c.libelleCaracteristique }}</span>
            </b-badge>
            <div class="jumbotron pt-10 pb-10 mt-2 mb-0">
              <p class="description">{{ logement.descLogement }}</p>
            </div>
          </div>
          
          <h4 class="border-bottom mb-2 mt-4">Localisation</h4>
          <b-row>
            <b-col><dl>
              <dd>{{$t('data.logement_detail_localisation_pays')}}</dd>
              <dt class="mt-n2 font-weight-bold fa-lg">{{ adresse.pays }}</dt>
            </dl></b-col>
            <b-col><dl>
              <dd>{{$t('data.logement_detail_localisation_ville')}}</dd>
              <dt class="mt-n2 font-weight-bold fa-lg">{{ adresse.ville }}</dt>
            </dl></b-col>
            <b-col><dl>
              <dd>{{$t('data.logement_detail_localisation_quartier')}}</dd>
              <dt class="mt-n2 font-weight-bold fa-lg">{{ adresse.quartier }}</dt>
            </dl></b-col>
          </b-row>
          <GmapMap
            :center="{
              lat: parseFloat(adresse.lat || 0),
              lng: parseFloat(adresse.lon || 0),
            }"
            :zoom="16"
            class="w-100"
            style="height: 340px"
          >
            <gmap-custom-marker :marker="marker"><img src="/img/mapMarker.png" /></gmap-custom-marker>
          </GmapMap>
          
          <h2 class="border-bottom mb-2 mt-4">{{$t('data.logement_detail_photos')}}</h2>
          <b-row>
            <div v-if="!photos.length" class="col-md-12">
              <div id="logement-main-img">
                <div class="logement-preview">
                  <img src="@/assets/img/imagelogementdefault-.jpg" alt="" />
                </div>
              </div>
            </div>
            <div v-if="photos.length == 1" class="col-md-12">
              <div id="logement-main-img">
                <div class="logement-preview">
                  <img :src="photos[0]" alt="" />
                </div>
              </div>
            </div>
            <div v-if="photos.length > 1" class="col-md-3">
              <div id="logement-imgs">
                <div
                  class="logement-preview logement-image"
                  v-for="(photo, i) in newPhotos"
                  :key="i"
                >
                  <img :src="photo" alt="Pas de photo" />
                </div>

                <div class="icon-up" @click="goUp">
                  <i class="fa fa-chevron-up"></i>
                </div>
                <div class="icon-down" @click="goDown">
                  <i class="fa fa-chevron-down"></i>
                </div>
              </div>
            </div>
            <div v-if="photos.length > 1" class="col-md-9">
              <div id="logement-main-img">
                <div class="logement-preview">
                  <img :src="currentPicture" alt="" />
                </div>
              </div>
            </div>
          </b-row>
        </b-container>

        <div
          v-if="section == 'annonces'"
          style="height: 75vh; overflow-y: auto; overflow-x: hidden"
        >
          <b-alert
            variant="info"
            show
            class="text-center"
            v-if="!logement.annonces.length"
          >
            <i class="fa fa-exclamation-triangle fa-3x float-left"></i>
            <span class="h4 d-inline-flex ml-2"
              >{{$t('data.logement_detail_pas_de_annonce')}}</span
            >
          </b-alert>
          <b-row>
            <b-col
              cols="12"
              v-for="(annonce, i) in logement.annonces"
              :key="annonce.idAnnonce || i"
            >
              <div class="card p-0 shadow">
                <div class="card-body p-0 d-flex">
                  <b-img
                    :src="photos[i] || photos[0]"
                    style="height: 8em; width: 8em"
                  ></b-img>
                  <div class="ml-2 p-2">
                    <div>
                      <b-badge
                        :variant="annonce.publish ? 'success' : 'danger'"
                        >{{
                          annonce.publish
                            ? "Déja publiée"
                            : "Pas encore publiée"
                        }}</b-badge
                      >&nbsp;
                      <b-badge variant="light">
                        <i class="fa fa-clock"></i>
                        {{$t('data.logement_detail_annonce_creee_le')}}
                        {{ $date(annonce.createdAt).format("DD.MM.YYYY") }}
                      </b-badge>
                    </div>
                    <h5 class="my-2">
                      <a href="#" class="text-red">{{
                        annonce.titreAnnonce
                      }}</a>
                    </h5>
                    <b-badge class="mr-2"
                      v-for="(tag, i) in annonce.tags.split(',')"
                      :key="i"
                      variant="secondary"
                      >{{ tag }}</b-badge
                    >
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-container v-if="section == 'depenses' || section == 'add-depense'">
          <div v-if="section == 'depenses'">
            <div class="d-flex justify-content-between border-bottom pb-1 mb-2">
              <div>
                <paginator
                  v-if="logement.depenses.length"
                  no-control
                  :total="logement.depenses.length"
                  :limit="perPage"
                  :page="currentPage"
                  @pageChanged="
                    (page) => {
                      currentPage = page;
                    }
                  "
                  @limitChanged="
                    (limit) => {
                      perPage = limit;
                    }
                  "
                />
              </div>
              <b-button
                :disabled="canAddDepenseToLogement"
                size="sm"
                variant="outline-secondary"
                @click.prevent="section = 'add-depense'"
                >{{$t('data.logement_detail_ajouter_depense')}}</b-button
              >
            </div>
            <b-alert variant="info" show v-if="!logement.depenses.length">
              <i class="fa fa-exclamation-triangle fa-3x float-left"></i>
              <span class="h4 d-inline-flex ml-2"
                > {{$t('data.logement_detail_pas_de_depense')}} </span
              >
            </b-alert>
            <div v-else style="height: 75vh">
              <div style="height: 90%; overflow-y: auto; overflow-x: hidden">
                <b-row class="layout-wrap">
                  <div
                    v-for="(depense, i) in itemsDepenses"
                    :key="depense.idDepense || i"
                    class="col-12 list-item list-item-thumb"
                  >
                    <depense
                      @deleted="removeDepense"
                      :depense="depense"
                      :source="source"
                      is-sub
                    />
                  </div>
                </b-row>
              </div>
            </div>
          </div>
          <div v-show="section == 'add-depense'">
            <depense-form
              :logement="logement"
              @cancel="section = 'depenses'"
              @depenseAdded="addedDepense"
              :provenance="provenance"
              :action="action"
            />
          </div>
        </b-container>

        <div
          v-if="section == 'locataire'"
          style="height: 38em; overflow-y: auto"
        >
          <b-alert
            variant="info"
            show
            class="text-center"
            v-if="!logement.occupations.length"
          >
            <i class="fa fa-exclamation-triangle fa-3x"></i> <br />
            <span class="h4 d-inline-flex ml-2"
              >{{$t('data.logement_detail_pas_de_locataire_dans_le_logement')}}</span
            >
          </b-alert>
          <b-card  v-for="(occupation, i) in logement.occupations" :key="occupation.idOccupation || i">
            <b-row>
                <b-col cols="2" class="px-1"><b-avatar :src="occupation.locataire.avatar || $router.options.base + 'img/profile-picture.jpg'" size="9em" /></b-col>
                <b-col>
                  <div class="d-flex justify-content-between align-items-center">
                    <b-badge class="w-90" variant="success" v-if="occupation.dateFin == null" >{{$t('data.logement_detail_bail_en_cours')}}</b-badge>
                    <b-badge class="w-90" variant="danger" v-else>{{ $t('data.logement_detail_bail_termine_le')}} {{ $date(occupation.dateFin).format("DD MMMM YYYY") }}</b-badge>
                    <b-dropdown size="sm" right variant="link" toggle-class="text-decoration-none" no-caret>
                      <template #button-content><i class="fa fa-ellipsis-h"></i></template>
                      <!--<b-dropdown-item :to="{name: 'habitants', query: {target: occupation.locataire.idLocataire}}">Détails du locataire</b-dropdown-item>
                              <b-dropdown-item>Détails de l'occupation</b-dropdown-item>-->
                      <b-dropdown-item @click.prevent="goToDetailLocataire(occupation)">{{$t('data.logement_detail_details_du_locataire')}}</b-dropdown-item>
                      <b-dropdown-item :to="{name: 'details-occupation', params: {id: occupation.idOccupation}}">{{$t('data.logement_detail_details_de_occupation')}}</b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <hr class="mt-1" />
                  <b-row>
                    <b-col>
                      <dl>
                        <dd>{{ $t('data.logement_detail_tooltip_nom_et_prenom') }}</dd>
                        <dt class="mt-n1 mb-2 font-weight-bold fa-lg">{{ occupation.locataire.nomLocataire + " " + occupation.locataire.prenomLocataire }}</dt>
                        <dd>{{ $t('data.occupation_numero_telephone') }}</dd>
                        <dt class="mt-n1 mb-2 font-weight-bold fa-lg">{{ occupation.locataire.tel }}</dt>
                        <dd>{{ $t('data.occupation_numero_cni') }}</dd>
                        <dt class="mt-n1 font-weight-bold fa-lg">{{ occupation.locataire.cniLocataire || 'non défini' }}</dt>
                    </dl>
                    </b-col>
                    <b-col>
                      <dl>
                        <dd>{{$t('data.occupation_informations_loyer')}}</dd>
                        <!--<dt class="mt-n1 mb-2 font-weight-bold fa-lg">{{ occupation.loyerBase }} F</dt>-->
                        <dt class="mt-n1 mb-2 font-weight-bold fa-lg">{{ occupation.loyerBase | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</dt>
                        <dd>{{ $t('data.detail_habitant_total_impayer') }}</dd>
                        <!--<dt class="mt-n1 mb-2"><b-badge class="fa-lg" variant="danger">{{ occupation.impayerTotal }} F</b-badge></dt>-->
                        <dt class="mt-n1 mb-2"><b-badge class="fa-lg" variant="danger">{{ occupation.impayerTotal | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</b-badge></dt>
                        <dd>Solde</dd>
                        <!--<dt class="mt-n1 mb-2"><b-badge class="fa-lg">{{ occupation.solde }} F</b-badge></dt>-->
                        <dt class="mt-n1 mb-2"><b-badge class="fa-lg">{{ occupation.solde | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</b-badge></dt>
                      </dl>
                    </b-col>
                  </b-row>
                </b-col> 
            </b-row>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import Depense from "@/views/gestion-immobiliere/depenses/Depense.vue";
import DepenseForm from "@/views/gestion-immobiliere/depenses/DepenseForm.vue";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import { mapGetters} from "vuex"
const php = require("phpjs");
export default {
  components: {
    VueEasyLightbox,
    VueUploadMultipleImage,
    Depense,
    DepenseForm,
    GmapCustomMarker,
  },
  props: {
    idLogement: { type: Number, required: true },
  },
  data: () => ({
    section: "caracteristiques",
    logement: {
		sousTypeLogement: {}, batiment: {cite: {}}, adresse: {},
		caracteristiques: [], photos: [], depenses: []

	},

    /**
     * Données manipuler par la section des photos
     */
    section_photo: {
      index: 0,
      visible: false,
      images: [],
    },
    //données manipulées pour l'ajout d'une dépense
    commandeDepense: false,
    action: "add",
    currentPage: 1,
    perPage: 10,
    source: 2,
    provenance: "2",
    marker: {
      lat: "",
      lng: "",
    },
    currentPicture: "",
    newPhotos: [],
    devise:null
  }),
  computed: {
     ...mapGetters(['hasAccess']),
    ...mapGetters(['user']),
    canViewListAnnoncesLogement(){
       return this.hasAccess('list_annonces_logement')  
    },
    canViewListDepensesLogement(){
       return this.hasAccess('list_depenses_details_logement')  
    },
    canViewLocatairesLogement(){
       return this.hasAccess('list_locataires_details_logement')  
    },
    canAddDepenseToLogement(){
       return this.hasAccess('add_depense_details_logement')  
    },
    photos() {
      return this.logement.photos.map((elt) => elt.image);
    },
    caracteristiques() {
      //return this.logement.caracteristiques
      return this.logement.caracteristiques.filter(
        (elt) => parseInt(elt.valeur) > 0
      );
    },
    itemsDepenses() {
      return php.array_slice(this.logement.depenses, this.offset, this.perPage);
    },
    offset() {
      return this.currentPage * this.perPage - this.perPage;
    },

    adresse() {
      if (!php.empty(this.logement.adresse)) {
        return this.logement.adresse
      }
      if (!php.empty(this.logement.batiment)) {
        if (!php.empty(this.logement.batiment.adresse)) {
          return this.logement.batiment.adresse
        }
      }
      return {}
    }
  },
  mounted() {
   /* console.log('user', this.user);
    if(this.user.entreprise.preferences.devise==null||this.user.entreprise.preferences.devise==''){
            this.devise='F'
        }else{
           this.devise= this.user.preferences.devise
        }*/
        this.devise='F'
    this.getLogement()
  },
  methods: {
    async getLogement() {
      this.logement = await axios.get('logements/' + this.idLogement).then(response => response.result);
      console.log("infos détails du logement",this.logement);
        this.newPhotos = this.photos.slice(0, 3);
        this.currentPicture = this.newPhotos[0];
        this.marker.lat = this.adresse.lat;
        this.marker.lng = this.adresse.lon;
    },
    /**Méthode permettant de voir le détails du locataire
     * directement à partir du détail du logement
     */
    goToDetailLocataire(occupation) {
      console.log("locataire", occupation);
      $("#editLayoutItem").modal("hide");
      setTimeout(() => {
        this.$router.push({
          name: "habitants",
          query: { target: occupation.locataire.idLocataire },
        });
      }, 100);
    },
    /**Méthode permettant de voir l'occupation
     * directement à partir du détail du logement
     */
    goToDetaiOccupation(occupation) {
      console.log("locataire", occupation);
      $("#editLayoutItem").modal("hide");
      setTimeout(() => {
        this.$router.push({
          name: "occupations",
          query: { target: occupation.idOccupation },
        });
      }, 100);
    },

    /**
     * réponse à l'évènement d'ajout d'une dépense
     * dans le détail de la cité, la réponse consiste juste à mettre à jour la liste des dépenses d'un logement
     */
    addedDepense(depense) {
      this.logement.depenses = [...this.logement.depenses, depense];
      this.section = 'depenses'
    },
    /**
     * Methodes relatives a la section des photos
     */
    section_photo_showImg(index) {
      this.section_photo.index = index;
      this.section_photo.visible = true;
    },
    section_photo_handleHide() {
      this.section_photo.visible = false;
    },
    section_photo_uploadImageSuccess(formData, index, fileList) {
      console.log("data", formData, index, fileList);
      // Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    /**
     * supprimer une dépense
     * ceci à pour but de  la marquer comme traitée en back
     */
    removeDepense(idDepense) {
      this.logement.depenses = this.logement.depenses.filter(
        (elt) => elt.idDepense != idDepense
      );
      this.$emit("depensesChanged", this.logement.depenses);
    },

    onCloseSet() {
      this.commandeDepense = false;
    },

    goUp() {
      let width = document.querySelector("#logement-main-img").offsetHeight / 3;
      let images = document.querySelectorAll(".logement-image");

      for (let i = 0; i < images.length; i++) {
        images[i].style.height = width + "px";
      }
      let index = this.photos.indexOf(this.currentPicture);

      this.newPhotos = this.photos.slice(index, index + 3);
      if (this.newPhotos.length < 3) {
        console.log("Taille incomplète !!!");
        let j = 0;
        while (this.newPhotos.length < 3) {
          this.newPhotos.push(this.photos[j]);
          j += 1;
        }
      }
      if (this.photos[index + 1]) {
        this.currentPicture = this.photos[index + 1];
      } else {
        this.currentPicture = this.photos[0];
      }
    },
    goDown() {},
  },
};
</script>

<style scoped>
#logement-main-img {
  height: 350px;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}

#logement-main-img .logement-preview,
#logement-main-img .logement-preview img {
  height: 100%;
  width: 100%;
}

#logement-imgs {
  overflow: hidden;
}

#logement-imgs .logement-preview {
  width: 100%;
  height: 8rem;
  overflow: inherit;
}
#logement-imgs .logement-preview img {
  width: 100%;
  height: 100%;
  transition: 1s all ease;
}
.logement-preview:nth-child(2) {
  border: 2px solid blue;
  transition: 1s all ease;
}

.icon-up,
.icon-down {
  background: gray;
  padding: 5px;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  position: absolute;
  left: 50%;
}

.icon-up {
  top: 0;
  transform: translate(-50%, -50%);
}

.icon-down {
  display: none;
  bottom: 0;
  transform: translate(-50%, 50%);
}

.icon-up .fa,
.icon-down .fa {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  padding-top: 8px;
}
.background {
  background: #f5365c;
}
p.description::first-letter {
  font-size: 40px;
  text-transform: uppercase;
}
</style>