<template>
    <div>
        <b-overlay :show="showOverlay" rounded="sm">
            <b-card>
                <b-row>
                    <b-col cols="2" class="px-1"><b-avatar :src="locataire.avatar" size="9em" /></b-col>
                    <b-col>
                        <dl class="row text-muted">
                            <dd class="col-3 mt-1">{{$t('data.solde_des_comptes')}}</dd>
                            <!--<dt class="col-3 mt-1"><b-badge variant="secondary" class="fa-lg">{{ locataire.solde }} F</b-badge></dt>-->
                            <dt class="col-3 mt-1"><b-badge variant="secondary" class="fa-lg">{{ locataire.solde | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</b-badge></dt>
                            <dd class="col-6 mt-1"></dd>
                            <dd class="col-3 mt-1">{{ $t('data.detail_habitant_impayer_loyer') }}</dd>
                            <!--<dt class="col-3 mt-1 fa-lg">{{ locataire.impayerLoyer }} F</dt>-->
                            <dt class="col-3 mt-1 fa-lg">{{ locataire.impayerLoyer | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</dt>
                            <dd class="col-3 mt-1">{{ $t('data.detail_habitant_impayer_charges') }}</dd>
                            <!--<dt class="col-3 mt-1 fa-lg">{{ locataire.impayerCharges }} F</dt>-->
                            <dt class="col-3 mt-1 fa-lg">{{ locataire.impayerCharges | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</dt>
                            <dd class="col-3 mt-1">{{ $t('data.detail_habitant_impayer_eau') }}</dd>
                            <!--<dt class="col-3 mt-1 fa-lg">{{ locataire.impayerEau }} F</dt>-->
                            <dt class="col-3 mt-1 fa-lg">{{ locataire.impayerEau | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</dt>
                            <dd class="col-3 mt-1">{{ $t('data.detail_habitant_impayer_electricite') }}</dd>
                            <!--<dt class="col-3 mt-1 fa-lg">{{ locataire.impayerEnergie }} F</dt>-->
                            <dt class="col-3 mt-1 fa-lg">{{ locataire.impayerEnergie | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</dt>
                            
                        </dl>
                        <dl class="row text-muted">
                            <dd class="col-3 mt-1">{{ $t('data.detail_habitant_total_impayer') }}</dd>
                            <dt class="col-3 mt-1">
                                <b-badge variant="danger" class="fa-lg" style="font-size: 2em">{{ locataire.impayerTotal | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</b-badge>
                            </dt>
                        </dl>
                        
                    </b-col> 
                </b-row>
            </b-card>
            <b-card no-body>
                <b-card-header header-tag="nav" class="pb-0">
                    <ul class="nav nav-pills custom-pills" role="tablist" >
                        <li class="nav-item"><a class="nav-link active font-weight-bold h6" data-toggle="pill" href="#occupations">{{$t('data.detail_habitant_occupations')}}</a></li>
                        <li class="nav-item"><a class="nav-link font-weight-bold h6" data-toggle="pill" href="#impayes">{{$t('data.impayes')}}</a></li>
                        <li class="nav-item"><a class="nav-link font-weight-bold h6" data-toggle="pill" href="#informations">{{$t('data.informations_personnelles')}}</a></li>
                        <li class="nav-item"><a class="nav-link font-weight-bold h6" data-toggle="pill" href="#messages">{{$t('data.hatitant_detail_messages')}}</a></li>
                    </ul>
                </b-card-header>
                <b-card-body>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="occupations">
                            <b-alert variant="info" class="text-center" show v-if="!locataire.occupations.length">
                                <i class="fa fa-exclamation-triangle fa-3x"></i> <br>
                                <span class="h4 d-inline-flex ml-2">{{$t('data.occupation_pas_de_occupation')}}</span>
                            </b-alert> 
                            <b-row v-else>
                                <b-col><b-table-simple hover small responsive sticky-header="600px" style="min-height: 20em">
                                    <b-thead head-variant="light">
                                        <b-tr>
                                            <b-th class="text-center">{{$t("data.detail_habitant_numero")}}</b-th>
                                            <b-th class="text-center">{{$t('data.detail_habitant_logement')}}</b-th>
                                            <b-th class="text-center">{{$t('data.occupation_informations_loyer')}}</b-th>
                                            <b-th class="text-center">{{$t('data.indexes_eau')}}</b-th>
                                            <b-th class="text-center">{{$t("data.indexes_electricite")}}</b-th>
                                            <b-th class="text-center">{{$t('data.solde')}}</b-th>
                                            <b-th class="text-center">{{$t('data.batiment_form_label_action')}}</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(occupation, index) in locataire.occupations" :key="occupation.idOccupation">
                                            <b-td class="p-2 text-center">{{ ++index }}</b-td>
                                            <b-td class="p-2 text-center">
                                                <b-badge><span class="font-weight-bold fa-lg">{{ occupation.logement.refLogement }}</span></b-badge>
                                                <span class="d-inline-block w-100 text-muted truncate">{{ occupation.logement.sousTypeLogement.libelleSousType }}</span>
                                            </b-td>
                                            <b-td class="p-2 text-center">
                                                <!--<b-badge><span class="font-weight-bold fa-lg">{{ occupation.loyerBase }}</span></b-badge>-->
                                                <b-badge><span class="font-weight-bold fa-lg">{{ occupation.loyerBase | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</span></b-badge>
                                                <span class="d-inline-block w-100 text-muted truncate">{{ occupation.modePaiement == 'prepayer' ? 'Prépayé' : 'Postpayé' }}</span>
                                            </b-td>
                                            <b-td class="p-2 text-center">
                                                <!--<b-badge><span class="font-weight-bold"> {{ occupation.impayerEau }}</span></b-badge>-->
                                                <b-badge><span class="font-weight-bold">{{ occupation.impayerEau | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</span></b-badge>
                                                <span class="d-inline-block w-100 text-muted truncate">{{ occupation.modeEau =='index' ? 'Suivi des index' : 'Taux forfaitaire' }}</span>
                                            </b-td>
                                            <b-td class="p-2 text-center">
                                                <!--<b-badge><span class="font-weight-bold">{{ occupation.impayerEnergie }}</span></b-badge>-->
                                                <b-badge><span class="font-weight-bold">{{ occupation.impayerEnergie | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</span></b-badge>
                                                <span class="d-inline-block w-100 text-muted truncate">{{ occupation.modeEnergie =='index' ? 'Suivi des index' : 'Taux forfaitaire' }}</span>
                                            </b-td>
                                            <b-td class="p-2 text-center">
                                                <!--<b-badge><span class="font-weight-bold">{{ occupation.solde + ' F' }}</span></b-badge>-->
                                                <b-badge><span class="font-weight-bold">{{ occupation.solde | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</span></b-badge>
                                                <span class="d-inline-block w-100" style="visibility:hidden">&nbsp;</span>
                                            </b-td>
                                            <b-td class="text-right">
                                                <b-dropdown right class="mt-n3">
                                                    <template #button-content><i class="fa fa-ellipsis-h"></i></template>
                                                    <b-dropdown-item @click.prevent="rechargeCompte(occupation.comptes)">{{$t('data.occupation_recharger_compte')}}</b-dropdown-item>
                                                    <b-dropdown-item :to="{name: 'occupation', params: {id: occupation.idOccupation}}">{{ $t('data.detail_habitant_facture_tooltip_acceder_occupation') }}</b-dropdown-item>
                                                    <b-dropdown-item :to="{name: 'logements', query: {target: occupation.idLogement}}">{{$t('data.afficher_le_logement')}}</b-dropdown-item>
                                                </b-dropdown>  
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple></b-col>
                            </b-row>
                        </div>
                        <div class="tab-pane fade" id="impayes">
                            <b-alert variant="info" class="text-center" show v-if="!impayes.length">
                                <i class="fa fa-exclamation-triangle fa-3x"></i> <br>
                                <span class="h4 d-inline-flex ml-2">{{$t('data.pas_de_facture_impaye')}}</span>
                            </b-alert> 
                            <b-row v-else>
                                <b-col><b-table-simple hover small responsive sticky-header="600px" style="min-height: 20em">
                                    <b-thead head-variant="light">
                                        <b-tr>
                                            <b-th class="text-center">{{$t("data.detail_habitant_numero")}}</b-th>
                                            <b-th class="text-center">{{$t("data.nature")}}</b-th>
                                            <b-th class="text-center">{{$t('data.occupation_montant')}}</b-th>
                                            <b-th class="text-center">{{$t('data.periode')}}</b-th>
                                            <b-th class="text-center">{{$t('data.detail_habitant_logement')}}</b-th>
                                            <b-th class="text-center">{{$t('data.batiment_form_label_action')}}</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(impaye, index) in impayes" :key="index">
                                            <b-td class="p-2 text-center">{{ ++index }}</b-td>
                                            <b-td class="p-2 text-center">
                                                <span class="d-inline-block w-100 font-weight-bold fa-lg">{{ impaye.nature + ' ' + impaye.type }}</span>
                                            </b-td>
                                            <b-td class="p-2 text-center">
                                                <!--<span class="d-inline-block w-100 font-weight-bold fa-lg">{{ impaye.montant + ' F' }}</span>-->
                                                <span class="d-inline-block w-100 font-weight-bold fa-lg">{{ impaye.montant | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</span>
                                            </b-td>
                                            <b-td class="p-2 text-center">
                                                <span class="d-inline-block w-100 font-weight-bold fa-lg">{{ $dayjs(impaye.periode).format('MMMM YYYY') }}</span>
                                            </b-td>
                                            <b-td class="p-2 text-center">
                                                <b-badge><span class="font-weight-bold fa-lg">{{ impaye.logement.refLogement }}</span></b-badge>
                                                <span class="d-inline-block w-100 text-muted truncate">{{ impaye.logement.sousTypeLogement.libelleSousType }}</span>
                                            </b-td>
                                            <b-td class="text-right">
                                                <b-button class="mt-n3" v-if="impaye.nature == 'charge'" v-b-tooltip="'Regler la charge'" @click.prevent="buyCharge(impaye)"><i class="fa fa-coins"></i></b-button> 
                                                <b-button class="mt-n3" v-if="impaye.nature == 'loyer'" v-b-tooltip="'Regler le loyer'" @click.prevent="buyLoyer(impaye)"><i class="fa fa-coins"></i></b-button> 
                                                <b-button class="mt-n3" v-if="impaye.nature == 'indexe'" v-b-tooltip="'Regler l\'indexe'" @click.prevent="buyIndexe(impaye)"><i class="fa fa-coins"></i></b-button> 
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple></b-col>
                            </b-row>
                        </div>
                        <div class="tab-pane fade" id="informations">
                            <div class="row">
                                <div class="col-lg-6">
                                    <dl class="row" >
                                        <dd class="mt-1 col-4">{{$t('data.profile_nom')}}</dd>
                                        <dt class="mt-1 col-8 font-weight-bold fa-lg">{{ locataire.nomLocataire }}</dt>
                                        <dd class="mt-1 col-4">{{$t("data.profile_prenom")}}</dd>
                                        <dt class="mt-1 col-8 font-weight-bold fa-lg">{{ locataire.prenomLocataire }}</dt>
                                        <dd class="mt-1 col-4">{{$t('data.profile_date_naissance')}}</dd>
                                        <dt class="mt-1 col-8 font-weight-bold fa-lg">{{ $dayjs(locataire.dateNaiss).format('DD MMMM YYYY') || 'non défini' }}</dt>
                                        <dd class="mt-1 col-4">{{$t('data.habitant_form_lieu_naissance')}}</dd>
                                        <dt class="mt-1 col-8 font-weight-bold fa-lg">{{ locataire.lieuNaiss || 'non défini' }}</dt>
                                    </dl>
                                </div>
                                <div class="col-lg-6">
                                    <dl class="row" >
                                        <dd class="mt-1 col-4">{{$t('data.occupation_numero_cni')}}</dd>
                                        <dt class="mt-1 col-8 font-weight-bold fa-lg">{{ locataire.cniLocataire || 'non défini' }}</dt>
                                        <dd class="mt-1 col-4">{{$t('data.occupation_numero_telephone')}}</dd>
                                        <dt class="mt-1 col-8 font-weight-bold fa-lg">{{ locataireTel(locataire) }}</dt>
                                        <dd class="mt-1 col-4">{{$t('data.occupation_email')}}</dd>
                                        <dt class="mt-1 col-8"><b-link style="color: #007bff;" href="#foo" disabled>{{ locataire.email || 'non défini' }}</b-link></dt>
                                        <dd class="mt-1 col-4">{{$t('data.profile_profession')}}</dd>
                                        <dt class="mt-1 col-8 font-weight-bold fa-lg">{{ locataire.profession || 'non défini' }}</dt>
                                    </dl>
                                </div>
           
                            </div>
                            <div class=" row d-flex justify-content-between">
                                <div style="width:45%">
                                    <b-card
                                        no-body
                                        style="max-width: 20rem"
                                    >
                                    <b-img :src="locataire.cniRecto" fluid alt="image cni recto" style="height: 15em"></b-img>
                                        <template #header>
                                            <h6 style="margin: 0 auto;" class="font-weight-bold">{{$t('data.locataire_detail_cni_recto')}}</h6>
                                        </template>
                                    </b-card>
                                </div>
                                <div style="width:45%">
                                    <b-card
                                        no-body
                                        style="max-width: 20rem"
                                    >
                                    <b-img :src="locataire.cniVerso" fluid alt="image cni verso" style="height: 15em"></b-img>
                                        <template #header>
                                            <h6 style="margin: 0 auto;" class="font-weight-bold">{{$t('data.locataire_detail_cni_verso')}}</h6>
                                        </template>
                                    </b-card>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="messages">
                            <b-alert variant="info" class="text-center" show v-if="!impayes.length">
                                <i class="fa fa-exclamation-triangle fa-3x"></i> <br>
                                <span class="h4 d-inline-flex ml-2">{{$t('data.pas_de_facture_impaye')}}</span>
                            </b-alert> 
                            <b-row v-else>
                                <div style="height: 38em; overflow-y: auto">
                                      <!--<div class="card d-flex flex-row justify-content-start align-items-start position-relative" style="height: 12rem">
                                            <div class="d-flex justify-content-center align-items-center overflow-hidden w-30 h-100 card-img">
                                                <a class="h-100 w-100" href="#editLayoutItem" data-toggle="modal" data-target="#editLayoutItem">
                                                    <img v-if="message.type =='charge' " src="`${$router.options.base}img/depenseImage.jpg`" alt="" class="responsive border-0 h-100 w-100">
                                                    <img v-if="message.type =='charges' " src="`${$router.options.base}img/depenseImage.jpg`" alt="" class="responsive border-0 h-100 w-100">
                                                    <img v-if="message.type =='information' " src="`${$router.options.base}img/depenseImage.jpg`" alt="" class="responsive border-0 h-100 w-100">
                                                </a>
                                            </div>
                                            <div class="flex-grow-1 min-width-zero card-content">
                                                <div class="card-body align-self-center d-flex flex-column justify-content-between min-width-zero align-items-md-center">
                                                    <div class="list-item-heading mb-1 truncate w-100 w-xs-100" href="#editLayoutItem" data-toggle="modal" data-target="#editLayoutItem">
                                                        <h5 class="text-red">{{ php.ucfirst(message.type.toLowerCase()) }}</h5>
                                                    </div>
                                                    <div class="card-content w-100">
                                                        <div class="jumbotron pt-5 pb-5 px-3 my-0" v-if="message.contenu"><p class="text-muted text-small">{{ message.contenu }}</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>-->
                                        <div class="card d-flex flex-row justify-content-start align-items-start position-relative" style="height: 12rem">
                                            <div class="d-flex justify-content-center align-items-center overflow-hidden w-30 h-100 card-img">
                                                <a class="h-100 w-100" href="#editLayoutItem" data-toggle="modal" data-target="#editLayoutItem">
                                                    <img :src="`${$router.options.base}img/facture_img.jpg`" alt="" class="responsive border-0 h-100 w-100">
                                                </a>
                                            </div>
                                            <div class="flex-grow-1 min-width-zero card-content">
                                                <div class="card-body align-self-center d-flex flex-column justify-content-between min-width-zero align-items-md-center">
                                                    <div class="list-item-heading mb-1 truncate w-100 w-xs-100" href="#editLayoutItem" data-toggle="modal" data-target="#editLayoutItem">
                                                    <h5 class="text-red">charges locatives</h5>
                                                    </div>
                                                    <div class="position-absolute" style="top: 0px; left: 60%;">
                                                        <span class="badge badge-secondary small">date d'envoi: <span> 26 mars 2022</span></span>
                                                    </div>
                                                    <div class="card-content w-100">
                                                        <div class="jumbotron pt-5 pb-5 px-3 my-0"><p class="text-muted text-small">W.w.w : IMMEUBLE FONGANG
                                                            Bonjour M. FOUODJI Jean Ferdinand recevez vos factures des Charges Locatives pour le mois de Novembre 2021!!!
                                                            reference: A022 loyer de base: 75 000 
                                                            facture en eau : 4 489 
                                                            facture en elect: 10 010 impayes: 273 493 Total : 287 994 Date limite de Paiement 05 Novembre 2021</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card d-flex flex-row justify-content-start align-items-start position-relative" style="height: 12rem">
                                            <div class="d-flex justify-content-center align-items-center overflow-hidden w-30 h-100 card-img">
                                                <a class="h-100 w-100" href="#editLayoutItem" data-toggle="modal" data-target="#editLayoutItem">
                                                    <img :src="`${$router.options.base}img/facture_img.jpg`" alt="" class="responsive border-0 h-100 w-100">
                                                </a>
                                            </div>
                                            <div class="flex-grow-1 min-width-zero card-content">
                                                <div class="card-body align-self-center d-flex flex-column justify-content-between min-width-zero align-items-md-center">
                                                    <div class="list-item-heading mb-1 truncate w-100 w-xs-100" href="#editLayoutItem" data-toggle="modal" data-target="#editLayoutItem">
                                                    <!-- <h5 class="text-red">{{ php.ucfirst(message.type.toLowerCase()) }}</h5>-->
                                                    <h5 class="text-red">charges locatives</h5>
                                                    </div>
                                                    <div class="position-absolute" style="top: 0px; left: 60%;">
                                                        <span class="badge badge-secondary small">date d'envoi: <span> 26 mars 2022</span></span>
                                                    </div>
                                                    <div class="card-content w-100">
                                                        <div class="jumbotron pt-5 pb-5 px-3 my-0"><p class="text-muted text-small">W.w.w : IMMEUBLE FONGANG
                                                            Bonjour M. FOUODJI Jean Ferdinand recevez vos factures des Charges Locatives pour le mois de Novembre 2021!!!
                                                            reference: A022 loyer de base: 75 000 
                                                            facture en eau : 4 489 
                                                            facture en elect: 10 010 impayes: 273 493 Total : 287 994 Date limite de Paiement 05 Novembre 2021</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card d-flex flex-row justify-content-start align-items-start position-relative" style="height: 12rem">
                                            <div class="d-flex justify-content-center align-items-center overflow-hidden w-30 h-100 card-img">
                                                <a class="h-100 w-100" href="#editLayoutItem" data-toggle="modal" data-target="#editLayoutItem">
                                                    <img :src="`${$router.options.base}img/facture_img.jpg`" alt="" class="responsive border-0 h-100 w-100">
                                                </a>
                                            </div>
                                            <div class="flex-grow-1 min-width-zero card-content">
                                                <div class="card-body align-self-center d-flex flex-column justify-content-between min-width-zero align-items-md-center">
                                                    <div class="list-item-heading mb-1 truncate w-100 w-xs-100" href="#editLayoutItem" data-toggle="modal" data-target="#editLayoutItem">
                                                    <!-- <h5 class="text-red">{{ php.ucfirst(message.type.toLowerCase()) }}</h5>-->
                                                    <h5 class="text-red">charges locatives</h5>
                                                    </div>
                                                    <div class="position-absolute" style="top: 0px; left: 60%;">
                                                        <span class="badge badge-secondary small">date d'envoi: <span> 26 mars 2022</span></span>
                                                    </div>
                                                    <div class="card-content w-100">
                                                        <div class="jumbotron pt-5 pb-5 px-3 my-0"><p class="text-muted text-small">W.w.w : IMMEUBLE FONGANG
                                                            Bonjour M. FOUODJI Jean Ferdinand recevez vos factures des Charges Locatives pour le mois de Novembre 2021!!!
                                                            reference: A022 loyer de base: 75 000 
                                                            facture en eau : 4 489 
                                                            facture en elect: 10 010 impayes: 273 493 Total : 287 994 Date limite de Paiement 05 Novembre 2021</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card d-flex flex-row justify-content-start align-items-start position-relative" style="height: 12rem">
                                            <div class="d-flex justify-content-center align-items-center overflow-hidden w-30 h-100 card-img">
                                                <a class="h-100 w-100" href="#editLayoutItem" data-toggle="modal" data-target="#editLayoutItem">
                                                    <img :src="`${$router.options.base}img/facture_img.jpg`" alt="" class="responsive border-0 h-100 w-100">
                                                </a>
                                            </div>
                                            <div class="flex-grow-1 min-width-zero card-content">
                                                <div class="card-body align-self-center d-flex flex-column justify-content-between min-width-zero align-items-md-center">
                                                    <div class="list-item-heading mb-1 truncate w-100 w-xs-100" href="#editLayoutItem" data-toggle="modal" data-target="#editLayoutItem">
                                                    <!-- <h5 class="text-red">{{ php.ucfirst(message.type.toLowerCase()) }}</h5>-->
                                                    <h5 class="text-red">charges locatives</h5>
                                                    </div>
                                                    <div class="position-absolute" style="top: 0px; left: 60%;">
                                                        <span class="badge badge-secondary small">date d'envoi: <span> 26 mars 2022</span></span>
                                                    </div>
                                                    <div class="card-content w-100">
                                                        <div class="jumbotron pt-5 pb-5 px-3 my-0"><p class="text-muted text-small">W.w.w : IMMEUBLE FONGANG
                                                            Bonjour M. FOUODJI Jean Ferdinand recevez vos factures des Charges Locatives pour le mois de Novembre 2021!!!
                                                            reference: A022 loyer de base: 75 000 
                                                            facture en eau : 4 489 
                                                            facture en elect: 10 010 impayes: 273 493 Total : 287 994 Date limite de Paiement 05 Novembre 2021</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card d-flex flex-row justify-content-start align-items-start position-relative" style="height: 12rem">
                                            <div class="d-flex justify-content-center align-items-center overflow-hidden w-30 h-100 card-img">
                                                <a class="h-100 w-100" href="#editLayoutItem" data-toggle="modal" data-target="#editLayoutItem">
                                                    <img :src="`${$router.options.base}img/facture_img.jpg`" alt="" class="responsive border-0 h-100 w-100">
                                                </a>
                                            </div>
                                            <div class="flex-grow-1 min-width-zero card-content">
                                                <div class="card-body align-self-center d-flex flex-column justify-content-between min-width-zero align-items-md-center">
                                                    <div class="list-item-heading mb-1 truncate w-100 w-xs-100" href="#editLayoutItem" data-toggle="modal" data-target="#editLayoutItem">
                                                    <!-- <h5 class="text-red">{{ php.ucfirst(message.type.toLowerCase()) }}</h5>-->
                                                    <h5 class="text-red">charges locatives</h5>
                                                    </div>
                                                    <div class="position-absolute" style="top: 0px; left: 60%;">
                                                        <span class="badge badge-secondary small">date d'envoi: <span> 26 mars 2022</span></span>
                                                    </div>
                                                    <div class="card-content w-100">
                                                        <div class="jumbotron pt-5 pb-5 px-3 my-0"><p class="text-muted text-small">W.w.w : IMMEUBLE FONGANG
                                                            Bonjour M. FOUODJI Jean Ferdinand recevez vos factures des Charges Locatives pour le mois de Novembre 2021!!!
                                                            reference: A022 loyer de base: 75 000 
                                                            facture en eau : 4 489 
                                                            facture en elect: 10 010 impayes: 273 493 Total : 287 994 Date limite de Paiement 05 Novembre 2021</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card d-flex flex-row justify-content-start align-items-start position-relative" style="height: 12rem">
                                            <div class="d-flex justify-content-center align-items-center overflow-hidden w-30 h-100 card-img">
                                                <a class="h-100 w-100" href="#editLayoutItem" data-toggle="modal" data-target="#editLayoutItem">
                                                    <img :src="`${$router.options.base}img/facture_img.jpg`" alt="" class="responsive border-0 h-100 w-100">
                                                </a>
                                            </div>
                                            <div class="flex-grow-1 min-width-zero card-content">
                                                <div class="card-body align-self-center d-flex flex-column justify-content-between min-width-zero align-items-md-center">
                                                    <div class="list-item-heading mb-1 truncate w-100 w-xs-100" href="#editLayoutItem" data-toggle="modal" data-target="#editLayoutItem">
                                                    <!-- <h5 class="text-red">{{ php.ucfirst(message.type.toLowerCase()) }}</h5>-->
                                                    <h5 class="text-red">charges locatives</h5>
                                                    </div>
                                                    <div class="position-absolute" style="top: 0px; left: 60%;">
                                                        <span class="badge badge-secondary small">date d'envoi: <span> 26 mars 2022</span></span>
                                                    </div>
                                                    <div class="card-content w-100">
                                                        <div class="jumbotron pt-5 pb-5 px-3 my-0"><p class="text-muted text-small">W.w.w : IMMEUBLE FONGANG
                                                            Bonjour M. FOUODJI Jean Ferdinand recevez vos factures des Charges Locatives pour le mois de Novembre 2021!!!
                                                            reference: A022 loyer de base: 75 000 
                                                            facture en eau : 4 489 
                                                            facture en elect: 10 010 impayes: 273 493 Total : 287 994 Date limite de Paiement 05 Novembre 2021</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card d-flex flex-row justify-content-start align-items-start position-relative" style="height: 12rem">
                                            <div class="d-flex justify-content-center align-items-center overflow-hidden w-30 h-100 card-img">
                                                <a class="h-100 w-100" href="#editLayoutItem" data-toggle="modal" data-target="#editLayoutItem">
                                                    <img :src="`${$router.options.base}img/facture_img.jpg`" alt="" class="responsive border-0 h-100 w-100">
                                                </a>
                                            </div>
                                            <div class="flex-grow-1 min-width-zero card-content">
                                                <div class="card-body align-self-center d-flex flex-column justify-content-between min-width-zero align-items-md-center">
                                                    <div class="list-item-heading mb-1 truncate w-100 w-xs-100" href="#editLayoutItem" data-toggle="modal" data-target="#editLayoutItem">
                                                    <!-- <h5 class="text-red">{{ php.ucfirst(message.type.toLowerCase()) }}</h5>-->
                                                    <h5 class="text-red">charges locatives</h5>
                                                    </div>
                                                    <div class="position-absolute" style="top: 0px; left: 60%;">
                                                        <span class="badge badge-secondary small">date d'envoi: <span> 26 mars 2022</span></span>
                                                    </div>
                                                    <div class="card-content w-100">
                                                        <div class="jumbotron pt-5 pb-5 px-3 my-0"><p class="text-muted text-small">W.w.w : IMMEUBLE FONGANG
                                                            Bonjour M. FOUODJI Jean Ferdinand recevez vos factures des Charges Locatives pour le mois de Novembre 2021!!!
                                                            reference: A022 loyer de base: 75 000 
                                                            facture en eau : 4 489 
                                                            facture en elect: 10 010 impayes: 273 493 Total : 287 994 Date limite de Paiement 05 Novembre 2021</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                              
                            </b-row>
                        </div>
                    </div>
                </b-card-body>
            </b-card>
        </b-overlay>


        <!--MODAL POUR PAYER UNE CHARGE -->
        <b-modal id="payerCharge_details_habitant" size="sm" :title="$t('data.occupation_payer_charge_titre')" @hidden="resetForm" no-close-on-backdrop hide-footer>
            <payer-charge-form @payed="handlePayer" :idCharge="payerCharge.idCharge" :montant="payerCharge.montant" />
        </b-modal>
        <!--MODAL POUR PAYER UN LOYER -->
        <b-modal id="payerLoyer_details_habitant" size="sm" :title="$t('data.occupation_reglement_loyer_titre')" @hidden="resetForm" no-close-on-backdrop hide-footer>
            <payer-loyer-form @payed="handlePayer" :idLoyer="payerLoyer.idLoyer" :montant="payerLoyer.montant" />
        </b-modal>
        <!--MODAL POUR PAYER UN INDEXE -->
        <b-modal id="payerIndexe_details_habitant" size="sm" :title="$t('data.occupation_reglement_indexe_titre')" @hidden="resetForm" no-close-on-backdrop hide-footer>
            <payer-indexe-form @payed="handlePayer" :idIndexe="payerIndexe.idIndexe" :montant="payerIndexe.montant" />
        </b-modal>

        <!--MODAL POUR RECHARGER UN COMPTE -->
        <b-modal id="rechargeCompte_details_habitant" size="sm" :title="$t('data.occupation_recharger_compte')" @hidden="resetForm" no-close-on-backdrop hide-footer>
            <recharge-compte-form @payed="handlePayer" :comptes="recharge.comptes" />
        </b-modal>
    </div>
</template>

<script>

import OccupationForm from "@/views/gestion-immobiliere/occupations/OccupationForm.vue"
import PayerChargeForm from '@/components/form/PayerChargeForm.vue';
import PayerLoyerForm from '@/components/form/PayerLoyerForm.vue';
import PayerIndexeForm from '@/components/form/PayerIndexeForm.vue';
import RechargeCompteForm from '@/components/form/RechargeCompteForm.vue'
import { mapGetters} from "vuex"
const php = require('phpjs')
export default {
    components: {
        OccupationForm,
        PayerChargeForm,
        PayerLoyerForm,
        PayerIndexeForm,
        RechargeCompteForm
    },
    props: {
        idLocataire: {type: Number, required: true}
    },
    data: () => ({
        showOverlay: true,

        locataire: {occupations: []},
        impayes: [],

        payerCharge: {montant: 0, idCharge: null},
        payerIndexe: {montant: 0, idIndexe: null},
        payerLoyer: {montant: 0, idLoyer: null},
        recharge: {comptes: []},



        action:0,
        section: 'occupations',
        /**
         * Données manipuler par la section occupations
         */
        currentPage: 1,
        perPage: 10,
        occupations:[],
        setOccupation:false,
        /**
         * Données manipuler par la section add-occupation
         */
        provenance:2,
        action:"add",

        show:false,
         modalClass: '',
        /**données manipulées pour afficher le modal de recharge
         * du compte du locataire
        */
        showModal: true,
        idOccupation:'',
        /**données manipulées pour effectuer un Transfert
         * intercomptes
         */
        transfert: {
            enabled: false,
            submitted: false,
            compteDebiteur: '',
            compteCredite: '',
            montant: null,
        },
        touslesComptes:[
             { text: 'loyer', value: 'compteLoyer' },
             { text: 'électricité', value: 'compteEnergie' },
             { text: 'eau', value: 'compteEau' }
        ],
        requiredCompteCrediteur:true,
        requiredCompteDebiteur:true,
        devise:null
    }),
    computed: {
        ...mapGetters(['user']),
        itemsOccupations(){
             return php.array_slice(this.locataire.occupations || [], this.offset, this.perPage)
        },
        offset() {
            return (this.currentPage * this.perPage) - this.perPage
        },
        comptesfiltres(){
            return this.touslesComptes.map(elt =>{
                if(elt.value!=this.transfert.compteDebiteur)
                return
            })
        }
    },
    watch: {
        idLocataire(value) {
            this.getLocataire(value)
        }
    },
    beforeMount() {
        /*javascript composant de drop down*/
        this.getLocataire()
    },
    mounted(){
        if(this.user.preferences.devise==null||this.user.preferences.devise==''){
            this.devise='F'
        }else{
            this.devise= this.user.preferences.devise
        }
    },
    methods: {
        locataireTel(locataire){
        if(locataire.tel!=null&&locataire.tel2!=null&&locataire.tel!=''&&locataire.tel2!=''){
            return locataire.tel+'/'+locataire.tel2
        }else{
            if(locataire.tel!=null&&locataire.tel!=''){
                return locataire.tel
            }
            return ''
        }
    },
        async getLocataire(idLocataire) {
            this.showOverlay = true
            this.locataire = await axios.get('locataires/' + (idLocataire || this.idLocataire)).then(response => response.result)
            this.showOverlay = false
            this.impayes = await axios.get('locataires/' + (idLocataire || this.idLocataire) + '/impayes').then(response => response.result)
        },

        buyCharge(charge) {
            this.payerCharge.idCharge = charge.id || charge.idCharge
            this.payerCharge.montant = charge.montant
            this.$bvModal.show('payerCharge_details_habitant')
        },
        buyLoyer(loyer) {
            this.payerLoyer.idLoyer = loyer.id || loyer.idLoyer
            this.payerLoyer.montant = loyer.montant
            this.$bvModal.show('payerLoyer_details_habitant')
        },
        buyIndexe(indexe) {
            this.payerIndexe.idIndexe = indexe.id || indexe.idIndexe
            this.payerIndexe.montant = indexe.montant
            this.$bvModal.show('payerIndexe_details_habitant')
        },
        rechargeCompte(comptes) {
            this.recharge.comptes = comptes
            this.$bvModal.show('rechargeCompte_details_habitant')
        },

        async handlePayer() {
            await this.getLocataire()
            this.$bvModal.hide('payerCharge_details_habitant')
            this.$bvModal.hide('payerLoyer_details_habitant')
            this.$bvModal.hide('payerIndexe_details_habitant')
            this.$bvModal.hide('rechargeCompte_details_habitant')
            this.$emit('change')
        },
        resetForm() {
            this.payerCharge = {montant: 0, idCharge: null}
            this.payerIndexe = {montant: 0, idIndexe: null}
            this.payerLoyer = {montant: 0, idLoyer: null}
        },
        

       /**
         * réponse à l'évènement d'ajout d'une occupation
         * dans le détail d'un habitant, la réponse consiste juste à mettre à jour la liste des occupations de l'habitant
         */
         addedOccupation() {
            axios.get(`locataires/${this.locataire.idLocataire}/occupations`).then(response => response.result || []).then(occupations => {
                this.locataire.occupations = occupations
                this.section = 'occupations'
            })
        },
        /**
         * recupération de la liste des occupations de l'habitant sélectionné si elles existent
         */
        getOccupations(){
            axios.get("locataires/"+locataire.idLocataire+"/occupations").then(response =>{
                 this.occupations=response.result;
            })
        },
        viewOccupationDetail(occupation){
            this.$root.$emit('show-detail-occupation',occupation)
        },

        /**
         * Ouvre le formulaire de recharge du compte d'un locataire
         */
        callRechargeForm(locataire) {
            console.log("locataire 1", locataire)
            this.recharge.enabled = true;
            this.locataire = locataire;
            this.$refs['modal-recharge'].show()
        },
        /**
         * valide la recharge du compte d'un locataire
         */
        validateRecharge(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.recharge.error = false;

            if (php.empty(this.recharge.montant) || this.recharge.montant < 1000) {
                this.recharge.error = true;
                return;
            }
            this.recharge.submitted = true;

            axios
                .post(`locataires/${this.locataire.idLocataire}/recharge`, this.recharge)
                .then(response => {
                    this.getHousing(false);
                    this.resetRechargeForm();
                    return App.notifySuccess(response.message);
                })
                .catch(error => {
                    this.recharge.submitted = false;
                    return App.alertError(error.message);
                });
        },
        /**
         * Reinitialise le formulaire de recharge du compte d'un locataire
         */
        resetRechargeForm() {
            this.recharge = {
                enabled: false,
                submitted: false,
                error: false,
                montant: null
            };
        },
        /**
         * ouvre le formulaire de transfert inter-comptes
         */
        callTranfertForm(locataire){
            console.log("locataire 2", locataire)
            this.transfert.enabled = true;
            this.locataire = locataire;
            this.$refs['modal-tranfert'].show()
        },
        /**methode permettant d'ecouter le compte débiteur sélectionné
         * ceci afin de filtrer la liste des comptes à envoyer
         * dans le v-select de selection du compte à crediter
         */
        filterAccountList(a) {
            this.comptesfiltres=[];
            let data = this.touslesComptes;
            /*for (let i = 0; i < this.sousTypesLogements.length; i++) {
                data.push(this.sousTypesLogements[i]);
            }*/
            let result = data.filter(x => x.value != this.transfert.compteDebiteur);
            this.comptesfiltres = result;
            console.log("sous-type",this.comptesfiltres)
        },
        /**
         * valide le transfert -intercomptes pour un locataire donné
         */
        validateTransfert(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.transfert.error = false;

            if (php.empty(this.transfert.montant) || php.empty(this.transfert.compteDebiteur) || php.empty(this.transfert.compteCredite)) {
                this.transfert.error = true;
                return;
            }
            this.transfert.submitted = true;

            axios
                .post(`locataires/${this.locataire.idLocataire}/transfert`, this.transfert)
                .then(response => {
                    //this.getHousing(false);
                    this.resetTransfertForm();
                    return App.notifySuccess(response.message);
                })
                .catch(error => {
                    this.transfert.submitted = false;
                    return App.alertError(error.message);
                });
        },
        /**
         * Reinitialise le formulaire de transfert inter-comptes
         */
        resetTransfertForm() {
            this.transfert = {
                enabled: false,
                submitted: false,
                error: false,
                compteDebiteur: null,
                compteCredite:null,
                montant: null
            };
        },

    }
}
</script>

<style scoped>
#payerCharge_details_habitant___BV_modal_outer_, #payerLoyer_details_habitant___BV_modal_outer_, 
#payerIndexe_details_habitant___BV_modal_outer_, #rechargeCompte_details_habitant___BV_modal_outer_ {
    z-index: 1055 !important;
}

</style>