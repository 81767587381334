<template>
  <div class="big-parent">
    <div class="main-parent">
      <div class="card resume-block">
        <div class="resume-block-header">
          <div class="text-center">
            <h4 class="card-title">
              {{ $t("data.occupation_occupation_numero") }}
              {{ pos + 1 + "/" + total }}
            </h4>
            <b-badge v-if="occupation.dateFin == null" variant="warning">{{
              $t("data.occupation_bail_en_cours")
            }}</b-badge>
            <b-badge v-else variant="danger"
              >{{ $t("data.occupation_bail_termine_le") }}<br />
              {{ $date(occupation.dateFin).format("DD MMMM YYYY") }}</b-badge
            >
          </div>
        </div>
        <div class="">
          <div class="resume-block-item long">
            <div class="block-item-label">
              <span> Locataire </span>
            </div>
            <div class="block-item-data">
              <div class="data-item allpay">
                <div class="data-icon">
                  <span> Occupé par </span>
                </div>
                <div class="data-value">
                  {{
                    occupation.locataire.nomLocataire +
                    " " +
                    occupation.locataire.prenomLocataire
                  }}
                </div>
              </div>
              <div
                class="data-item"
                v-show="
                  !empty(occupation.locataire.tel) ||
                  !empty(occupation.locataire.tel2)
                "
              >
                <div class="data-icon">
                  <span> Téléphone </span>
                </div>
                <div class="data-value">
                  {{
                    occupation.locataire.tel + " / " + occupation.locataire.tel2
                  }}
                </div>
              </div>
              <div
                class="data-item"
                v-show="!empty(occupation.locataire.profession)"
              >
                <div class="data-icon">
                  <span> Profession </span>
                </div>
                <div class="data-value">
                  {{ occupation.locataire.profession }}
                </div>
              </div>
              <div
                class="data-item"
                v-show="
                  !empty(occupation.locataire.email) ||
                  !empty(occupation.locataire.email2)
                "
              >
                <div class="data-icon">
                  <span>
                    {{ $t("data.occupation_email") }}
                  </span>
                </div>
                <div class="data-value">
                  {{
                    occupation.locataire.email +
                    " / " +
                    occupation.locataire.email2
                  }}
                </div>
              </div>
            </div>

            <dl class="row text-muted" style="display: none">
              <div
                class="d-flex pl-3 w-100"
                v-if="!empty(occupation.locataire.cniLocataire)"
              >
                <dd class="mt-1"><i class="fa fa-id-card"></i></dd>
                <dt
                  class="mt-1 ml-3 truncate fa-lg"
                  :v-b-tooltip="$t('data.occupation_numero_cni')"
                >
                  {{ occupation.locataire.cniLocataire }}
                </dt>
              </div>
              <div
                class="d-flex pl-3 w-100"
                v-if="
                  !empty(occupation.locataire.tel) ||
                  !empty(occupation.locataire.tel2)
                "
              >
                <dd class="mt-1"><i class="fa fa-phone"></i></dd>
                <dt
                  class="mt-1 ml-3 truncate fa-lg"
                  :v-b-tooltip="$t('data.occupation_numero_telephone')"
                >
                  {{
                    occupation.locataire.tel + " / " + occupation.locataire.tel2
                  }}
                </dt>
              </div>
              <div
                class="d-flex pl-3 w-100"
                v-if="
                  !empty(occupation.locataire.email) ||
                  !empty(occupation.locataire.email2)
                "
              >
                <dd class="mt-1"><i class="fa fa-envelope"></i></dd>
                <dt
                  class="mt-1 ml-3 truncate fa-lg"
                  :v-b-tooltip="$t('data.occupation_email')"
                >
                  {{
                    occupation.locataire.email +
                    " / " +
                    occupation.locataire.email2
                  }}
                </dt>
              </div>
              <div
                class="d-flex pl-3 w-100"
                v-if="!empty(occupation.locataire.dateNaiss)"
              >
                <dd class="mt-1"><i class="fas fa-calendar-alt"></i></dd>
                <dt
                  class="mt-1 ml-3 truncate fa-lg"
                  :v-b-tooltip="$t('data.occupation_date_naissance')"
                >
                  {{
                    $date(occupation.locataire.dateNaiss).format("DD MMMM YYYY")
                  }}
                </dt>
              </div>
            </dl>
            <b-button
              @click.prevent="showDetails('habitant')"
              size="sm"
              block
              variant="outline-secondary"
              >{{ $t("data.occupation_voir_dossier") }}</b-button
            >
          </div>
          <div class="resume-block-item" :class="{ long: block1 }">
            <div class="block-item-label">
              <span @click="block1 = !block1">
                Impayés de l'occupation
                <i v-show="!block1" class="ik ik-chevron-down"></i>
                <i v-show="block1" class="ik ik-chevron-up"></i>
              </span>
            </div>
            <div class="block-item-data">
              <div
                class="data-item allpay"
                v-b-tooltip.right="$t('data.occupation_total_impayer')"
              >
                <div class="data-icon">
                  <span> Total Impayé </span>
                </div>
                <div class="data-value">
                  {{
                    occupation.impayerTotal
                      | currency(devise, 0, {
                        symbolOnLeft: false,
                        spaceBetweenAmountAndSymbol: true,
                        thousandsSeparator: " ",
                      })
                  }}
                </div>
              </div>
              <div
                class="data-item"
                v-b-tooltip.right="$t('data.occupation_impayer_loyer')"
              >
                <div class="data-icon">
                  <span>
                    {{ $t("data.occupation_informations_loyer") }}
                  </span>
                </div>
                <div class="data-value">
                  {{
                    occupation.impayerLoyer
                      | currency(devise, 0, {
                        symbolOnLeft: false,
                        spaceBetweenAmountAndSymbol: true,
                        thousandsSeparator: " ",
                      })
                  }}
                </div>
              </div>
              <div
                class="data-item"
                v-b-tooltip.right="$t('data.occupation_impayer_lumiere')"
              >
                <div class="data-icon">
                  <span> Lumière </span>
                </div>
                <div class="data-value">
                  {{
                    occupation.impayerEnergie
                      | currency(devise, 0, {
                        symbolOnLeft: false,
                        spaceBetweenAmountAndSymbol: true,
                        thousandsSeparator: " ",
                      })
                  }}
                </div>
              </div>
              <div
                class="data-item"
                v-b-tooltip.right="$t('data.occupation_impayer_eau')"
              >
                <div class="data-icon">
                  <span> Eau </span>
                </div>
                <div class="data-value">
                  {{
                    occupation.impayerEau
                      | currency(devise, 0, {
                        symbolOnLeft: false,
                        spaceBetweenAmountAndSymbol: true,
                        thousandsSeparator: " ",
                      })
                  }}
                </div>
              </div>
              <div
                class="data-item"
                v-b-tooltip.right="$t('data.occupation_impayer_charges')"
              >
                <div class="data-icon">
                  <span> Charges </span>
                </div>
                <div class="data-value">
                  {{
                    occupation.impayerCharges
                      | currency(devise, 0, {
                        symbolOnLeft: false,
                        spaceBetweenAmountAndSymbol: true,
                        thousandsSeparator: " ",
                      })
                  }}
                </div>
              </div>
            </div>
            <b-button
              @click.prevent="$bvModal.show('reglerImpayer')"
              size="sm"
              block
              variant="outline-secondary"
              >Regler les impayés</b-button
            >

            <dl class="row mb-0 text-muted" style="display: none">
              <dd class="mt-1 col-5 pr-0">
                <i
                  style="width: 1.5em"
                  class="d-inline-block fa fa-money-bill"
                ></i>
                {{ $t("data.occupation_informations_loyer") }}
              </dd>
              <dt
                class="mt-1 col-7 pl-1 truncate font-weight-bold fa-lg"
                v-b-tooltip.right="$t('data.occupation_impayer_loyer')"
              >
                {{
                  occupation.impayerLoyer
                    | currency(devise, 0, {
                      symbolOnLeft: false,
                      spaceBetweenAmountAndSymbol: true,
                      thousandsSeparator: " ",
                    })
                }}
              </dt>
              <dd class="mt-1 col-5 pr-0">
                <i
                  style="width: 1.5em"
                  class="d-inline-block fa fa-lightbulb"
                ></i>
                Lumière
              </dd>
              <dt
                class="mt-1 col-7 pl-1 truncate font-weight-bold fa-lg"
                v-b-tooltip.right="$t('data.occupation_impayer_lumiere')"
              >
                {{
                  occupation.impayerEnergie
                    | currency(devise, 0, {
                      symbolOnLeft: false,
                      spaceBetweenAmountAndSymbol: true,
                      thousandsSeparator: " ",
                    })
                }}
              </dt>
              <dd class="mt-1 col-5 pr-0">
                <i style="width: 1.5em" class="d-inline-block fa fa-water"></i>
                Eau
              </dd>
              <dt
                class="mt-1 col-7 pl-1 truncate font-weight-bold fa-lg"
                v-b-tooltip.right="$t('data.occupation_impayer_eau')"
              >
                {{
                  occupation.impayerEau
                    | currency(devise, 0, {
                      symbolOnLeft: false,
                      spaceBetweenAmountAndSymbol: true,
                      thousandsSeparator: " ",
                    })
                }}
              </dt>
              <dd class="mt-1 col-5 pr-0">
                <i
                  style="width: 1.5em"
                  class="d-inline-block fa fa-file-invoice"
                ></i>
                Charges
              </dd>
              <dt
                class="mt-1 col-7 pl-1 truncate font-weight-bold fa-lg"
                v-b-tooltip.right="$t('data.occupation_impayer_charges')"
              >
                {{
                  occupation.impayerCharges
                    | currency(devise, 0, {
                      symbolOnLeft: false,
                      spaceBetweenAmountAndSymbol: true,
                      thousandsSeparator: " ",
                    })
                }}
              </dt>
              <dd class="mt-3 col-5 pr-0">
                <i
                  style="width: 1.5em"
                  class="d-inline-block fa fa-dollar-sign"
                ></i>
                Total
              </dd>
              <dt
                class="mt-1 col-7 pl-1 truncate fa-2x"
                v-b-tooltip.right="$t('data.occupation_total_impayer')"
                :class="{ 'text-danger': totalImpayes > 10000 }"
              >
                {{
                  occupation.impayerTotal
                    | currency(devise, 0, {
                      symbolOnLeft: false,
                      spaceBetweenAmountAndSymbol: true,
                      thousandsSeparator: " ",
                    })
                }}
              </dt>
            </dl>
          </div>
          <div class="resume-block-item long">
            <div class="block-item-label">
              <span>
                {{ $t("data.occupation_logement") }}
              </span>
            </div>
            <div class="block-item-data logement">
              <div class="data-item allpay">
                <div class="data-icon">
                  <i class="fa fa-dollar-sign"></i>
                  <span>
                    {{ occupation.logement.refLogement }}
                  </span>
                </div>
                <div class="data-value">
                  {{ occupation.logement.sousTypeLogement.libelleSousType }}
                </div>
              </div>
              <div
                class="data-item"
                :v-b-tooltip="$t('data.occupation_voir_plage_loyer')"
              >
                <div class="data-icon">
                  <i class="fa fa-money-bill"></i>
                  <span>
                    {{ $t("data.occupation_informations_loyer") }}
                  </span>
                </div>
                <div class="data-value">
                  {{
                    occupation.logement.prixMin
                      | currency(devise, 0, {
                        symbolOnLeft: false,
                        spaceBetweenAmountAndSymbol: true,
                        thousandsSeparator: " ",
                      })
                  }}
                  -
                  {{
                    occupation.logement.prixMax
                      | currency(devise, 0, {
                        symbolOnLeft: false,
                        spaceBetweenAmountAndSymbol: true,
                        thousandsSeparator: " ",
                      })
                  }}
                </div>
              </div>
              <div
                class="data-item"
                :v-b-tooltip="$t('data.occupation_localisation')"
              >
                <div class="data-icon">
                  <i class="fa fa-lightbulb"></i>
                  <span> Situé à </span>
                </div>
                <div class="data-value">
                  {{ localisation }}
                </div>
              </div>
              <div
                class="data-item"
                :v-b-tooltip="$t('data.occupation_batiment')"
                v-show="occupation.logement.batiment"
              >
                <div class="data-icon">
                  <i class="fa fa-lightbulb"></i>
                  <span> Lié au batiment </span>
                </div>
                <div class="data-value">
                  {{ occupation.logement.batiment.nomBatiment }}
                </div>
              </div>
              <div
                class="data-item"
                :v-b-tooltip="$t('data.occupation_cite')"
                v-show="occupation.logement.batiment"
              >
                <div class="data-icon">
                  <i class="fa fa-lightbulb"></i>
                  <span> Lié à la cité </span>
                </div>
                <div class="data-value">
                  {{ occupation.logement.batiment.cite.nomCite }}
                </div>
              </div>
            </div>
            <!-- <span class="d-inline-block w-100 mb-1 text-center h4">{{ occupation.logement.refLogement }}</span>    
                        <span class="d-inline-block w-100 mb-1 text-center text-muted">{{ occupation.logement.sousTypeLogement.libelleSousType }}</span>   
                        <dl class="row text-muted">
                            <div class="d-flex pl-3 w-100">
                                <dd class="mt-1"><i class="fa fa-dollar-sign"></i></dd>
                                <dt class="mt-1 ml-3 truncate fa-lg" :v-b-tooltip="$t('data.occupation_voir_plage_loyer')">{{ occupation.logement.prixMin | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ' '  }) }} - {{ occupation.logement.prixMax | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ' '  }) }}</dt>
                            </div>
                            <div class="d-flex pl-3 w-100">
                                <dd class="mt-1"><i class="fa fa-map-marker-alt"></i></dd>
                                <dt class="mt-1 ml-3 truncate fa-lg" :v-b-tooltip="$t('data.occupation_localisation')">{{ localisation }}</dt>
                            </div>
                            <div class="d-flex pl-3 w-100">
                                <dd class="mt-1" v-if="occupation.logement.batiment"><i class="fa fa-building"></i></dd>
                                <dt class="mt-1 ml-3 truncate fa-lg" v-if="occupation.logement.batiment" :v-b-tooltip="$t('data.occupation_batiment')">{{ occupation.logement.batiment.nomBatiment }}</dt>
                            </div>
                            <div class="d-flex pl-3 w-100">
                                <dd class="mt-1" v-if="occupation.logement.batiment"><i class="fas fa-city"></i></dd>
                                <dt class="mt-1 ml-3 truncate fa-lg" v-if="occupation.logement.batiment" :v-b-tooltip="$t('data.occupation_cite')">{{ occupation.logement.batiment.cite.nomCite }}</dt>
                            </div>
                        </dl> -->
            <b-button
              @click.prevent="showDetails('logement')"
              size="sm"
              block
              variant="outline-secondary"
              >{{ $t("data.occupation_voir_details") }}</b-button
            >
          </div>
        </div>
      </div>
      <div class="card great-block">
        <!-- <ul class="nav nav-pills custom-pills" >
                    <li class="nav-item">
                        <a class="nav-link font-weight-bold h6" href="#" :class="{ 'active': section == 'generalites' }" @click.prevent="section = 'generalites'">{{$t('data.occupation_generalite')}}</a>
                    </li>
                    <li v-if="canViewLoyersOccupation" class="nav-item">
                        <a class="nav-link font-weight-bold h6" href="#" :class="{ 'active': section == 'loyers' }" @click.prevent="section = 'loyers'">{{$t('data.occupation_loyers')}}</a>
                    </li>
                      <li v-if="canViewChargesOccupation" class="nav-item">
                        <a class="nav-link font-weight-bold h6" href="#" :class="{ 'active': section == 'charges' }" @click.prevent="section = 'charges'">{{$t('data.occupation_charges')}}</a>
                    </li>
                    <li  class="nav-item" v-if="occupation.modeEau == 'index' || occupation.modeEnergie == 'index' && canViewIndexesOccupation">
                        <a class="nav-link font-weight-bold h6" href="#" :class="{ 'active': section == 'indexes' }" @click.prevent="section = 'indexes'">{{$t('data.occupation_indexes')}}</a>
                    </li>
                    <li v-if="canViewDepotsOccupation" class="nav-item"><a class="nav-link font-weight-bold h6" data-toggle="pill" href="#" :class="{ 'active': section == 'depots' }" @click.prevent="section = 'depots'">Dépôts</a></li>
                    <li v-if="canViewContratOccupation" class="nav-item" ><a class="nav-link font-weight-bold h6" data-toggle="pill" href="#" :class="{ 'active': section == 'contrats' }" @click.prevent="section = 'contrats'">Contrat</a></li>
                    <li v-if="canViewCautionsOccupation" class="nav-item">
                        <a class="nav-link font-weight-bold h6" data-toggle="pill" href="#" :class="{ 'active': section == 'cautions' }" @click.prevent="section = 'cautions'">{{$t('data.occupation_caution')}}</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle font-weight-bold pb-0 h6" data-toggle="dropdown" href="#">Options &nbsp;<i class="fa-sm fas fa-caret-down"></i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a v-if="canRechargeCompte" class="dropdown-item font-weight-bold bg-danger text-white" href="#" @click.prevent="$bvModal.show('rechargeCompte')">Recharger le compte</a>
                            <a v-if="canReglerImpayes" class="dropdown-item font-weight-bold" href="#" @click.prevent="$bvModal.show('reglerImpayer')">Regler les impayés</a>
                            <a v-if="canCreateCompte" class="dropdown-item font-weight-bold" href="#" @click.prevent="$bvModal.show('creerCompte')">Ajouter un compte</a>
                            <div class="dropdown-divider"></div>
                            <a v-if="canAddCharge" class="dropdown-item font-weight-bold" href="#" @click.prevent="$bvModal.show('chargeForm')">Ajouter une charge</a>
                            <a v-if="canGenerateCharges" class="dropdown-item font-weight-bold" href="#" @click.prevent="$bvModal.show('generateCharges')">Générer les charges</a>
                            <a v-if="canGenerateLoyers" class="dropdown-item font-weight-bold" href="#" @click.prevent="$bvModal.show('generateLoyers')">Générer les loyers</a>
                            <div class="dropdown-divider"></div>

                            <a v-if="canAddCaution" class="dropdown-item font-weight-bold" href="#" @click.prevent="callCautionForm">Ajouter une caution</a>
                            <div class="dropdown-divider"></div>
                            <a v-if="canEditOccupation" class="dropdown-item font-weight-bold" href="#" @click.prevent="$emit('makeUpdate', occupation)">{{$t('data.occupation_editer_occupation')}}</a>
                            <a class="dropdown-item font-weight-bold" href="#" @click.prevent="$bvModal.show('closeOccupation')" v-if="occupation.dateFin == null&&canCloseOccupation">{{ $t('data.occupation_cloturer_bail') }}</a>
                        </div>
                    </li>
                </ul> -->
        <div class="navbar">
          <div class="navbar-left">
            <div
              :class="{ active: section == 'generalites' }"
              @click.prevent="section = 'generalites'"
            >
              Informations générales
            </div>
            <div
              :class="{ active: section == 'loyers' }"
              @click.prevent="section = 'loyers'"
            >
              Loyers
            </div>
            <div
              :class="{ active: section == 'indexes' }"
              v-if="
                occupation.modeEau == 'index' ||
                (occupation.modeEnergie == 'index' && canViewIndexesOccupation)
              "
              @click.prevent="section = 'indexes'"
            >
              {{ $t("data.occupation_indexes") }}
            </div>
            <div
              :class="{ active: section == 'charges' }"
              @click.prevent="section = 'charges'"
            >
              Charges
            </div>
            <div
              :class="{ active: section == 'depots' }"
              @click.prevent="section = 'depots'"
            >
              Depôt
            </div>
            <div
              :class="{ active: section == 'contrats' }"
              @click.prevent="section = 'contrats'"
            >
              Contrat
            </div>
            <div
              :class="{ active: section == 'cautions' }"
              @click.prevent="section = 'cautions'"
            >
              Caution
            </div>
          </div>
          <div class="navbar-right">
            <div class="more-options">
              <button
                class="more-btn"
                v-show="!moreOption"
                @click="moreOption = true"
              >
                <i class="ik ik-grid"></i>
                <span>Menu</span>
              </button>
              <div class="more-option-container" v-show="moreOption">
                <div class="close-more-option" @click="moreOption = false">
                  <div>
                    <i class="ik ik-x"></i>
                  </div>
                </div>
                <div class="option-item" @click="chargeCompteVisible = true">
                  <i></i>
                  <span>Recharger le compte</span>
                </div>
                <div class="option-item" @click="genererLoyerVisible = true">
                  <i></i>
                  <span>Générer les loyers</span>
                </div>
                <div class="option-item" @click="genererChargeVisible = true">
                  <i></i>
                  <span>Générer les charges</span>
                </div>
                <!-- <div
                  class="option-item"
                  @click.prevent="$bvModal.show('creerCompte')"
                >
                  <i></i>
                  <span>Ajouter un compte</span>
                </div> -->
                <div class="option-item" @click="createChargeVisible = true">
                  <i></i>
                  <span>Ajouter une charge</span>
                </div>
                <div class="option-item" @click="createCautionVisible = true">
                  <i></i>
                  <span>Ajouter une caution</span>
                </div>
                <!-- <div class="option-item">
                  <i></i>
                  <span>Modifier le mode de consommation</span>
                </div> -->
                <!-- <div
                  class="option-item"
                  @click.prevent="$emit('makeUpdate', occupation)"
                >
                  <i></i>
                  <span>Editer l'occupation</span>
                </div> -->
                <div class="option-item" @click="setEdition">
                  <i></i>
                  <span>editer le bail</span>
                </div>
                <div class="option-item" @click="clotureBailVisible = true">
                  <i></i>
                  <span>Clôturer le bail</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-content" id="pills-tabContent" style="height: auto">
          <div class="tab-pane fade show active" style="padding: 0; margin: 0">
            <!-- Généralités -->
            <div class="card-body" v-show="section == 'generalites'">
              <!-- <h4 class="border-bottom">{{$t('data.occupation_informations')}}</h4> -->
              <b-row class="gen-block">
                <div class="gen-block-item">
                  <div class="icon-area">
                    <div class="icon-block">
                      <i class="ik ik-anchor"></i>
                    </div>
                    <span>
                      {{ $t("data.solde") }}
                    </span>
                  </div>
                  <span class="value">
                    <span>
                      <span v-show="soldeOccupation == 0" style="">{{
                        soldeOccupation
                          | currency(devise, 0, {
                            symbolOnLeft: false,
                            spaceBetweenAmountAndSymbol: true,
                            thousandsSeparator: " ",
                          })
                      }}</span>
                      <span v-show="soldeOccupation > 0" style="">{{
                        soldeOccupation
                          | currency(devise, 0, {
                            symbolOnLeft: false,
                            spaceBetweenAmountAndSymbol: true,
                            thousandsSeparator: " ",
                          })
                      }}</span>
                    </span>
                  </span>
                </div>
                <div class="gen-block-item">
                  <div class="icon-area">
                    <div class="icon-block">
                      <i class="ik ik-anchor"></i>
                    </div>
                    <span>
                      {{ $t("data.occupation_informations_loyer") }}
                    </span>
                  </div>
                  <span class="value">
                    <span>
                      {{
                        occupation.loyerBase
                          | currency(devise, 0, {
                            symbolOnLeft: false,
                            spaceBetweenAmountAndSymbol: true,
                            thousandsSeparator: " ",
                          })
                      }}
                    </span>
                  </span>
                </div>
                <div class="gen-block-item">
                  <div class="icon-area">
                    <div class="icon-block">
                      <i class="ik ik-anchor"></i>
                    </div>
                    <span>
                      {{ $t("data.occupation_informations_mode_paiement") }}
                    </span>
                  </div>
                  <span class="value">
                    <span>
                      {{ occupation.modePaiement }}
                    </span>
                  </span>
                </div>
                <div class="gen-block-item">
                  <div class="icon-area">
                    <div class="icon-block">
                      <i class="ik ik-anchor"></i>
                    </div>
                    <span>
                      {{ $t("data.occupation_informations_date_debut_bail") }}
                    </span>
                  </div>
                  <span class="value">
                    <span>
                      {{ $date(occupation.dateDeb).format("DD MMMM YYYY") }}
                    </span>
                  </span>
                </div>
                <div class="gen-block-item" v-show="occupation.dateFin">
                  <div class="icon-area">
                    <div class="icon-block">
                      <i class="ik ik-anchor"></i>
                    </div>
                    <span>
                      {{ $t("data.occupation_informations_date_fin_bail") }}
                    </span>
                  </div>
                  <span class="value">
                    <span>
                      {{ $date(occupation.dateFin).format("DD MMMM YYYY") }}
                    </span>
                  </span>
                </div>
                <div class="gen-block-item">
                  <div class="icon-area">
                    <div class="icon-block">
                      <i class="ik ik-anchor"></i>
                    </div>
                    <span>
                      {{ $t("data.occupation_caution_payee") }}
                    </span>
                  </div>
                  <span class="value">
                    <span>
                      {{
                        occupation.caution
                          | currency(devise, 0, {
                            symbolOnLeft: false,
                            spaceBetweenAmountAndSymbol: true,
                            thousandsSeparator: " ",
                          })
                      }}
                    </span>
                  </span>
                </div>
                <div class="gen-block-item">
                  <div class="icon-area">
                    <div class="icon-block">
                      <i class="ik ik-anchor"></i>
                    </div>
                    <span v-show="occupation.modeEnergie === 'index'">
                      {{ $t("data.pu_lumiere") }}
                    </span>
                    <span v-show="occupation.modeEnergie !== 'index'">
                      {{ $t("data.forfait_lumiere") }}
                    </span>
                  </div>
                  <span class="value">
                    <span>
                      {{
                        occupation.caution
                          | currency(devise, 0, {
                            symbolOnLeft: false,
                            spaceBetweenAmountAndSymbol: true,
                            thousandsSeparator: " ",
                          })
                      }}
                    </span>
                  </span>
                </div>
                <div class="gen-block-item">
                  <div class="icon-area">
                    <div class="icon-block">
                      <i class="ik ik-anchor"></i>
                    </div>
                    <span v-show="occupation.modeEau === 'index'">
                      {{ $t("data.pu_eau") }}
                    </span>
                    <span v-show="occupation.modeEnergie !== 'index'">
                      {{ $t("data.forfait_eau") }}
                    </span>
                  </div>
                  <span class="value">
                    <span>
                      {{
                        occupation.puEau
                          | currency(devise, 0, {
                            symbolOnLeft: false,
                            spaceBetweenAmountAndSymbol: true,
                            thousandsSeparator: " ",
                          })
                      }}
                      /
                      <span v-if="occupation.modeEau == 'index'"
                        >m<sup>3</sup> </span
                      ><span v-else>{{ $t("data.occupation_mois") }} </span>
                    </span>
                  </span>
                </div>
                <div
                  class="gen-block-item"
                  v-show="!empty(occupation.dureeBail)"
                >
                  <div class="icon-area">
                    <div class="icon-block">
                      <i class="ik ik-anchor"></i>
                    </div>
                    <span>
                      {{ $t("data.occupation_duree_prevu_bail") }}
                    </span>
                  </div>
                  <span class="value">
                    <span>
                      {{
                        occupation.dureeBail + " " + $t("data.occupation_mois")
                      }}
                    </span>
                  </span>
                </div>
                <div class="gen-block-item">
                  <div class="icon-area">
                    <div class="icon-block">
                      <i class="ik ik-anchor"></i>
                    </div>
                    <span>
                      {{ $t("data.occupation_cree_le") }}
                    </span>
                  </div>
                  <span class="value">
                    <span>
                      {{
                        $date(occupation.createdAt).format(
                          "DD MMMM YYYY - HH:mm"
                        )
                      }}
                    </span>
                  </span>
                </div>
              </b-row>
              <b-row class="mb-1 row" style="display: none">
                <div
                  class="
                    text-dark
                    pt-2
                    rounded
                    col-lg-6 col-md-12 col-sm-12
                    my-2
                  "
                  style="background: #fff"
                >
                  <dl class="row">
                    <dd class="mt-1 col-5">{{ $t("data.solde") }}</dd>
                    <!--<dt class="mt-1 col-7 font-weight-bold fa-lg"> <span class="" style="">{{ occupation.solde + ' FCFA' }}</span> </dt>-->
                    <dt
                      v-if="soldeOccupation == 0"
                      class="mt-1 col-7 font-weight-bold fa-lg"
                    >
                      <span class="" style="">{{
                        soldeOccupation
                          | currency(devise, 0, {
                            symbolOnLeft: false,
                            spaceBetweenAmountAndSymbol: true,
                            thousandsSeparator: " ",
                          })
                      }}</span>
                    </dt>
                    <dt
                      v-if="soldeOccupation > 0"
                      class="mt-1 col-7 font-weight-bold fa-lg"
                      style="color: #008000"
                    >
                      {{
                        soldeOccupation
                          | currency(devise, 0, {
                            symbolOnLeft: false,
                            spaceBetweenAmountAndSymbol: true,
                            thousandsSeparator: " ",
                          })
                      }}
                    </dt>
                    <dd class="mt-1 col-5">
                      {{ $t("data.occupation_informations_loyer") }}
                    </dd>
                    <!--<dt class="mt-1 col-7 font-weight-bold fa-lg"> <span class="" style="">{{ occupation.loyerBase + ' FCFA' }}</span> </dt>-->
                    <dt class="mt-1 col-7 font-weight-bold fa-lg">
                      <span class="" style="">{{
                        occupation.loyerBase
                          | currency(devise, 0, {
                            symbolOnLeft: false,
                            spaceBetweenAmountAndSymbol: true,
                            thousandsSeparator: " ",
                          })
                      }}</span>
                    </dt>
                    <dd class="mt-1 col-5">
                      {{ $t("data.occupation_informations_mode_paiement") }}
                    </dd>
                    <dt class="mt-1 col-7 font-weight-bold fa-lg">
                      <span class="" style="">
                        {{ occupation.modePaiement }}</span
                      >
                    </dt>
                    <dd class="mt-1 col-5">
                      {{ $t("data.occupation_informations_date_debut_bail") }}
                    </dd>
                    <dt class="mt-1 col-7 font-weight-bold fa-lg">
                      {{ $date(occupation.dateDeb).format("DD MMMM YYYY") }}
                    </dt>
                    <dd class="mt-1 col-5" v-if="occupation.dateFin">
                      {{ $t("data.occupation_informations_date_fin_bail") }}
                    </dd>
                    <dt
                      class="mt-1 col-7 font-weight-bold fa-lg"
                      v-if="occupation.dateFin"
                    >
                      {{ $date(occupation.dateFin).format("DD MMMM YYYY") }}
                    </dt>
                  </dl>
                </div>
                <div
                  class="
                    text-dark
                    pt-2
                    rounded
                    col-lg-6 col-md-12 col-sm-12
                    my-2
                  "
                  style="background: #fff"
                >
                  <dl class="row">
                    <dd class="mt-1 col-5">
                      {{ $t("data.occupation_caution_payee") }}
                    </dd>
                    <!--<dt class="mt-1 col-7 font-weight-bold fa-lg"><span class="" style=""> {{ occupation.caution + ' FCFA' }}</span></dt>-->
                    <dt class="mt-1 col-7 font-weight-bold fa-lg">
                      <span class="" style="">{{
                        occupation.caution
                          | currency(devise, 0, {
                            symbolOnLeft: false,
                            spaceBetweenAmountAndSymbol: true,
                            thousandsSeparator: " ",
                          })
                      }}</span>
                    </dt>
                    <dd
                      v-if="occupation.modeEnergie === 'index'"
                      class="mt-1 col-5"
                    >
                      {{ $t("data.pu_lumiere") }}
                    </dd>
                    <dd v-else class="mt-1 col-5">
                      {{ $t("data.forfait_lumiere") }}
                    </dd>
                    <!--<dt class="mt-1 col-7 font-weight-bold fa-lg">{{ occupation.puEnergie + 'F' }} / {{ occupation.modeEnergie == 'index' ? 'kw' : $t('data.occupation_mois') }}</dt>-->
                    <dt class="mt-1 col-7 font-weight-bold fa-lg">
                      {{
                        occupation.puEnergie
                          | currency(devise, 0, {
                            symbolOnLeft: false,
                            spaceBetweenAmountAndSymbol: true,
                            thousandsSeparator: " ",
                          })
                      }}
                      /
                      {{
                        occupation.modeEnergie == "index"
                          ? "kw"
                          : $t("data.occupation_mois")
                      }}
                    </dt>
                    <dd
                      v-if="occupation.modeEau === 'index'"
                      class="mt-1 col-5"
                    >
                      {{ $t("data.pu_eau") }}
                    </dd>
                    <dd v-else class="mt-1 col-5">
                      {{ $t("data.forfait_eau") }}
                    </dd>
                    <!--<dt class="mt-1 col-7 font-weight-bold fa-lg">{{ occupation.puEau + 'F' }} / <span v-if="occupation.modeEau == 'index'">m<sup>3</sup></span><span v-else>{{$t('data.occupation_mois')}}</span></dt>-->
                    <dt class="mt-1 col-7 font-weight-bold fa-lg">
                      {{
                        occupation.puEau
                          | currency(devise, 0, {
                            symbolOnLeft: false,
                            spaceBetweenAmountAndSymbol: true,
                            thousandsSeparator: " ",
                          })
                      }}
                      /
                      <span v-if="occupation.modeEau == 'index'"
                        >m<sup>3</sup></span
                      ><span v-else>{{ $t("data.occupation_mois") }}</span>
                    </dt>
                    <dd class="mt-1 col-5" v-if="!empty(occupation.dureeBail)">
                      {{ $t("data.occupation_duree_prevu_bail") }}
                    </dd>
                    <dt
                      class="mt-1 col-7 font-weight-bold fa-lg"
                      v-if="!empty(occupation.dureeBail)"
                    >
                      <span class="" style="">{{
                        occupation.dureeBail + " " + $t("data.occupation_mois")
                      }}</span>
                    </dt>
                    <dd class="mt-1 col-5">
                      {{ $t("data.occupation_cree_le") }}
                    </dd>
                    <dt class="mt-1 col-7 font-weight-bold fa-lg">
                      {{
                        $date(occupation.createdAt).format(
                          "DD MMMM YYYY - HH:mm"
                        )
                      }}
                    </dt>
                  </dl>
                </div>
              </b-row>
              <!-- <h4 class="border-bottom">{{$t('data.graphe')}}</h4> -->
              <div></div>
            </div>
            <!-- Indexes -->
            <div v-show="section == 'indexes'">
              <b-container
                class="my-3"
                v-if="
                  occupation.modeEau == 'index' ||
                  occupation.modeEnergie == 'index'
                "
              >
                <b-overlay :show="showOverlayTable" rounded="sm">
                  <ul class="nav nav-tabs">
                    <li class="nav-item" v-if="'index' == occupation.modeEau">
                      <a
                        class="nav-link"
                        :class="{ active: tab_indexe == 'eau' }"
                        @click.prevent="tab_indexe = 'eau'"
                        data-toggle="pill"
                        href="#"
                        >{{ $t("data.occupation_indexes_eau") }}</a
                      >
                    </li>
                    <li
                      class="nav-item"
                      v-if="'index' == occupation.modeEnergie"
                    >
                      <a
                        class="nav-link"
                        :class="{ active: tab_indexe == 'energie' }"
                        @click.prevent="tab_indexe = 'energie'"
                        data-toggle="pill"
                        href="#"
                        >{{ $t("data.occupation_indexes_electricte") }}</a
                      >
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane fade show active mt-1">
                      <b-table
                        sticky-header="600px"
                        striped
                        hover
                        responsive
                        :items="indexes"
                        :fields="[
                          { key: 'index', label: '' },
                          { key: 'periode', label: 'Période', sortable: true },
                          {
                            key: 'consommation',
                            label: $t('data.occupation_consommation'),
                            sortable: true,
                          },
                          {
                            key: 'montant',
                            label: $t('data.occupation_montant'),
                            sortable: true,
                          },
                          {
                            key: 'avance',
                            label: $t('data.occupation_avance'),
                            sortable: true,
                          },
                          {
                            key: 'reste',
                            label: $t('data.occupation_reste'),
                            sortable: true,
                          },
                          {
                            key: 'show_details',
                            label: $t('data.occupation_details'),
                          },
                        ]"
                        style="min-height: 20em"
                      >
                        <template #cell(index)="data">{{
                          ++data.index
                        }}</template>
                        <template #cell(periode)="data">{{
                          $dayjs(data.item.periode).format("MMMM YYYY")
                        }}</template>
                        <template #cell(consommation)="data">{{
                          data.item.consommation
                        }}</template>
                        <template #cell(montant)="data"
                          ><span
                            :class="{
                              'text-success':
                                data.item.montant <= data.item.avance,
                              'text-warning':
                                data.item.montant > data.item.avance,
                              'text-danger': empty(data.item.avance),
                            }"
                          >
                            {{
                              data.item.montant
                                | currency(devise, 0, {
                                  symbolOnLeft: false,
                                  spaceBetweenAmountAndSymbol: true,
                                  thousandsSeparator: " ",
                                })
                            }}
                          </span></template
                        >
                        <template #cell(avance)="data">{{
                          (data.item.avance || 0)
                            | currency(devise, 0, {
                              symbolOnLeft: false,
                              spaceBetweenAmountAndSymbol: true,
                              thousandsSeparator: " ",
                            })
                        }}</template>
                        <template #cell(reste)="data">{{
                          empty(data.item.reste)
                            ? data.item.montant - data.item.avance || 0
                            : data.item.reste
                              | currency(devise, 0, {
                                symbolOnLeft: false,
                                spaceBetweenAmountAndSymbol: true,
                                thousandsSeparator: " ",
                              })
                        }}</template>
                        <template #cell(show_details)="row"
                          ><b-form-checkbox
                            v-model="row.detailsShowing"
                            @change="row.toggleDetails"
                          ></b-form-checkbox
                        ></template>
                        <template #row-details="row">
                          <b-card class="m-0">
                            <b-row>
                              <b-col>
                                <dl class="row text-muted">
                                  <dd class="mt-1 col-5">
                                    {{ $t("data.occupation_index_courant") }}
                                  </dd>
                                  <dt class="mt-1 col-7">
                                    {{ row.item.nouveau }}
                                  </dt>
                                  <dd class="mt-1 col-5">
                                    {{ $t("data.occupation_ancien_index") }}
                                  </dd>
                                  <dt class="mt-1 col-7">
                                    {{ row.item.ancien }}
                                  </dt>
                                </dl>
                              </b-col>
                              <b-col>
                                <dl class="row text-muted">
                                  <dd class="mt-1 col-5">
                                    {{ $t("data.occupation_status") }}
                                  </dd>
                                  <dt class="mt-1 col-7">
                                    <div
                                      class="d-flex"
                                      v-if="row.item.montant == 0"
                                    >
                                      <b-badge>{{
                                        $t(
                                          "data.occupation_consommation_non_consomme"
                                        )
                                      }}</b-badge>
                                    </div>
                                    <div
                                      class="d-flex"
                                      v-else-if="empty(row.item.avance)"
                                    >
                                      <b-badge variant="danger">{{
                                        $t(
                                          "data.occupation_consommation_non_payer"
                                        )
                                      }}</b-badge>
                                      <b-button
                                        class="ml-1 py-1"
                                        size="sm"
                                        @click.prevent="
                                          buyIndexe(row.item, tab_indexe)
                                        "
                                        v-b-tooltip="'Payer maintenant'"
                                        ><i class="fa fa-coins"></i
                                      ></b-button>
                                    </div>
                                    <div
                                      class="d-flex"
                                      v-else-if="
                                        row.item.montant > row.item.avance
                                      "
                                    >
                                      <b-badge variant="warning">{{
                                        $t("data.occupation_avancer")
                                      }}</b-badge>
                                      <b-button
                                        class="ml-1 py-1"
                                        size="sm"
                                        @click.prevent="
                                          buyIndexe(row.item, tab_indexe)
                                        "
                                        v-b-tooltip="'Completer le paiement'"
                                        ><i class="fa fa-coins"></i
                                      ></b-button>
                                    </div>
                                    <b-badge variant="success" v-else>{{
                                      $t("data.occupation_payer")
                                    }}</b-badge>
                                  </dt>
                                  <dd class="mt-1 col-5">
                                    {{ $t("data.occupation_date_paiement") }}
                                  </dd>
                                  <dt class="mt-1 col-7">
                                    {{
                                      empty(row.item.datePaiement)
                                        ? "Non disponible"
                                        : $date(row.item.datePaiement).format(
                                            "DD MMMM YYYY"
                                          )
                                    }}
                                  </dt>
                                </dl>
                              </b-col>
                            </b-row>
                          </b-card>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-overlay></b-container
              >
            </div>
            <!-- Loyers -->
            <div v-show="section == 'loyers'">
              <b-container
                ><b-overlay :show="showOverlayTable" rounded="sm">
                  <b-table
                    sticky-header="600px"
                    striped
                    hover
                    responsive
                    :items="loyers"
                    :fields="[
                      { key: 'index', label: '' },
                      {
                        key: 'mois',
                        label: $t('data.occupation_mois'),
                        sortable: true,
                      },
                      {
                        key: 'annees',
                        label: $t('data.occupation_annee'),
                        sortable: true,
                      },
                      {
                        key: 'montant',
                        label: $t('data.occupation_montant_a_payer'),
                        sortable: true,
                      },
                      {
                        key: 'montantPayer',
                        label: $t('data.occupation_montant_paye'),
                        sortable: true,
                      },
                      {
                        key: 'observation',
                        label: $t('data.occupation_observation'),
                      },
                    ]"
                    style="min-height: 20em"
                  >
                    <template #cell(index)="data">{{ ++data.index }}</template>
                    <template #cell(mois)="data">{{
                      $dayjs(data.item.periode).format("MMMM")
                    }}</template>
                    <template #cell(annees)="data">{{
                      $dayjs(data.item.periode).format("YYYY")
                    }}</template>
                    <!--<template #cell(montant)="data"> {{ data.item.montant }} F</template>-->
                    <template #cell(montant)="data">{{
                      data.item.montant
                        | currency(devise, 0, {
                          symbolOnLeft: false,
                          spaceBetweenAmountAndSymbol: true,
                          thousandsSeparator: " ",
                        })
                    }}</template>
                    <!--<template #cell(montantPayer)="data"> {{ data.item.montantPayer }} F</template>-->
                    <template #cell(montantPayer)="data">{{
                      data.item.montantPayer
                        | currency(devise, 0, {
                          symbolOnLeft: false,
                          spaceBetweenAmountAndSymbol: true,
                          thousandsSeparator: " ",
                        })
                    }}</template>
                    <template #cell(observation)="data">
                      <div
                        class="d-flex"
                        v-if="
                          empty(data.item.montantPayer) ||
                          data.item.montantPayer <= 0
                        "
                      >
                        <b-badge variant="danger">{{
                          $t("data.occupation_consommation_non_payer")
                        }}</b-badge>
                        <b-button
                          class="ml-1 py-1"
                          size="sm"
                          @click.prevent="buyLoyer(data.item)"
                          :v-b-tooltip="
                            $t('data.occupation_tooltip_payer_maintenant')
                          "
                          ><i class="fa fa-coins"></i
                        ></b-button>
                      </div>
                      <div
                        class="d-flex"
                        v-else-if="data.item.montantPayer < data.item.montant"
                      >
                        <b-badge
                          variant="warning"
                          class="bg-yellow"
                          style="color: black !important"
                          >{{ $t("data.occupation_avancer") }}</b-badge
                        >
                        <b-button
                          class="ml-1 py-1"
                          size="sm"
                          @click.prevent="buyLoyer(data.item)"
                          :v-b-tooltip="
                            $t('data.occupation_tooltip_completer_paiement')
                          "
                          ><i class="fa fa-coins"></i
                        ></b-button>
                      </div>
                      <b-badge variant="success" v-else
                        >{{ $t("data.occupation_payer_le") }}
                        {{
                          $dayjs(data.item.datePaiement).format("DD MMMM YYYY")
                        }}</b-badge
                      >
                    </template>
                  </b-table>
                </b-overlay></b-container
              >
            </div>
            <!-- Charges -->
            <div v-show="section == 'charges'">
              <b-container
                ><b-overlay :show="showOverlayTable" rounded="sm">
                  <b-table
                    sticky-header="600px"
                    striped
                    hover
                    responsive
                    :items="charges"
                    :fields="[
                      { key: 'index', label: '' },
                      { key: 'periode', label: 'Période', sortable: true },
                      { key: 'nature', label: 'Nature', sortable: true },
                      {
                        key: 'montant',
                        label: $t('data.occupation_a_payer'),
                        sortable: true,
                      },
                      {
                        key: 'montantPayer',
                        label: $t('data.occupation_payer'),
                        sortable: true,
                      },
                      {
                        key: 'observation',
                        label: $t('data.occupation_observation'),
                      },
                      { key: 'statut', label: $t('data.occupation_status') },
                      {
                        key: 'Action',
                        label: $t('data.batiment_form_label_action'),
                      },
                    ]"
                    style="min-height: 20em"
                  >
                    <template #cell(index)="data">{{ ++data.index }}</template>
                    <template #cell(periode)="data">{{
                      $dayjs(data.item.periode).format("MMMM YYYY")
                    }}</template>
                    <template #cell(nature)="data">{{
                      data.item.typeCharge.libelle
                    }}</template>
                    <!--<template #cell(montant)="data"> {{ data.item.montant }} F</template>-->
                    <template #cell(montant)="data">{{
                      data.item.montant
                        | currency(devise, 0, {
                          symbolOnLeft: false,
                          spaceBetweenAmountAndSymbol: true,
                          thousandsSeparator: " ",
                        })
                    }}</template>
                    <!--<template #cell(montantPayer)="data"> {{ data.item.montantPayer }} F</template>-->
                    <template #cell(montantPayer)="data">{{
                      data.item.montantPayer
                        | currency(devise, 0, {
                          symbolOnLeft: false,
                          spaceBetweenAmountAndSymbol: true,
                          thousandsSeparator: " ",
                        })
                    }}</template>
                    <template #cell(observation)="data">
                      <b-badge
                        variant="danger"
                        v-if="
                          empty(data.item.montantPayer) ||
                          data.item.montantPayer <= 0
                        "
                        >{{
                          $t("data.occupation_consommation_non_payer")
                        }}</b-badge
                      >
                      <b-badge
                        variant="warning"
                        v-else-if="data.item.montantPayer < data.item.montant"
                        >{{ $t("data.occupation_avancer") }}</b-badge
                      >
                      <b-badge variant="success" v-else
                        >{{ $t("data.occupation_payer_le") }}
                        {{
                          $dayjs(data.item.datePaiement).format("DD MMMM YYYY")
                        }}</b-badge
                      >
                    </template>
                    <template #cell(statut)="data">
                      <b-badge variant="success" v-if="data.item.etatCharge">{{
                        $t("data.occupation_actif")
                      }}</b-badge>
                      <b-badge variant="danger" v-else>{{
                        $t("data.occupation_terminer")
                      }}</b-badge>
                    </template>
                    <template #cell(action)="row">
                      <b-dropdown size="sm" right>
                        <template #button-content
                          ><i class="fa fa-ellipsis-h"></i
                        ></template>
                        <div
                          style="
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 99;
                            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                          "
                        >
                          <b-dropdown-item
                            href="#"
                            v-if="canCloseEtatCharge"
                            @click.prevent="changeState(row.item)"
                            >{{
                              row.item.etatCharge
                                ? $t("data.occupation_marquer_comme_terminer")
                                : $t("data.occupation_activer_charge")
                            }}</b-dropdown-item
                          >
                          <b-dropdown-item
                            href="#"
                            v-if="canPayCharge"
                            @click.prevent="buyCharge(row.item)"
                            >{{
                              $t("data.occupation_charge_payer")
                            }}</b-dropdown-item
                          >
                          <b-dropdown-divider />
                          <b-dropdown-item
                            href="#"
                            @click="row.toggleDetails"
                            >{{
                              $t("data.occupation_details")
                            }}</b-dropdown-item
                          >
                        </div>
                      </b-dropdown>
                    </template>
                    <template #row-details="row">
                      <b-card class="m-0">
                        <div class="d-flex justify-content-end">
                          <a
                            href="#"
                            @click="row.toggleDetails"
                            class="text-danger"
                            ><i class="fa fa-times"></i
                          ></a>
                        </div>
                        <dl>
                          <dt>
                            {{ $t("data.occupation_description_charge") }}
                          </dt>
                          <dd>
                            <p>{{ row.item.observation }}</p>
                          </dd>
                        </dl>
                      </b-card>
                    </template>
                  </b-table>
                </b-overlay></b-container
              >
            </div>
            <!-- Depots -->
            <div v-show="section == 'depots'">
              <b-container
                ><b-overlay :show="showOverlayTable" rounded="sm">
                  <b-table
                    sticky-header="600px"
                    striped
                    hover
                    responsive
                    :items="depots"
                    :fields="[
                      { key: 'index', label: '' },
                      { key: 'date', label: 'Date du dépôt', sortable: true },
                      {
                        key: 'montant',
                        label: 'Montant du dépôt',
                        sortable: true,
                      },
                      {
                        key: 'compte',
                        label: 'Compté crédité',
                        sortable: true,
                      },
                      {
                        key: 'observation',
                        label: $t('data.occupation_observation'),
                      },
                      {
                        key: 'show_details',
                        label: $t('data.occupation_details'),
                      },
                    ]"
                    style="min-height: 20em"
                  >
                    <template #cell(index)="data">{{ ++data.index }}</template>
                    <template #cell(date)="data">{{
                      $dayjs(data.item.dateDepot).format(
                        "dddd, DD MMM YYYY - HH:mm"
                      )
                    }}</template>
                    <!--<template #cell(montant)="data"> {{ data.item.montant }} F</template>-->
                    <template #cell(montant)="data">{{
                      data.item.montant
                        | currency(devise, 0, {
                          symbolOnLeft: false,
                          spaceBetweenAmountAndSymbol: true,
                          thousandsSeparator: " ",
                        })
                    }}</template>
                    <template #cell(compte)="data">{{
                      data.item.compte.typeCompte
                    }}</template>
                    <template #cell(observation)="data">
                      {{ data.item.description | truncate(75) }}</template
                    >
                    <template #cell(show_details)="row"
                      ><b-form-checkbox
                        v-model="row.detailsShowing"
                        @change="row.toggleDetails"
                      ></b-form-checkbox
                    ></template>
                    <template #row-details="row">
                      <b-card class="m-0">
                        <b-row v-if="row.item.recu">
                          <b-col>
                            <h4>{{ $t("data.occupation_recu_paiement") }} :</h4>
                          </b-col>
                          <b-col>
                            <div class="card d-flex flex-row mb-3">
                              <a
                                class="d-flex card-img"
                                @click.prevent="showDetails"
                                href="#"
                              >
                                <img
                                  :src="row.item.recu"
                                  alt=""
                                  style="height: 10em"
                                  class="list-thumbnail responsive border-0"
                                />
                              </a>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row v-if="!row.item.recu">
                          <b-col>
                            <h4 class="text-muted">
                              {{ $t("data.occupation_aucun_recu_paiement") }}
                            </h4>
                          </b-col>
                        </b-row>
                      </b-card>
                    </template>
                  </b-table>
                </b-overlay></b-container
              >
            </div>
            <!-- Cautions -->
            <div v-show="section == 'cautions'">
              <b-container
                ><b-overlay :show="showOverlayTable" rounded="sm">
                  <b-table
                    sticky-header="600px"
                    striped
                    hover
                    responsive
                    :items="cautions"
                    :fields="[
                      { key: 'index', label: '' },
                      { key: 'date', label: 'Date', sortable: true },
                      { key: 'nature', label: 'Nature', sortable: true },
                      {
                        key: 'montant',
                        label: $t('data.occupation_caution_montant_verse'),
                        sortable: true,
                      },
                      { key: 'statut', label: $t('data.occupation_status') },
                      {
                        key: 'Action',
                        label: $t('data.batiment_form_label_action'),
                      },
                    ]"
                    style="min-height: 20em"
                  >
                    <template #cell(index)="data">{{ ++data.index }}</template>
                    <template #cell(date)="data">{{
                      $dayjs(data.item.dateCaution).format("MMMM YYYY")
                    }}</template>
                    <template #cell(nature)="data">{{
                      data.item.type
                    }}</template>
                    <template #cell(montant)="data">{{
                      data.item.montant
                        | currency(devise, 0, {
                          symbolOnLeft: false,
                          spaceBetweenAmountAndSymbol: true,
                          thousandsSeparator: " ",
                        })
                    }}</template>
                    <template #cell(statut)="data">
                      <b-badge
                        variant="success"
                        v-if="data.item.etat == 'réglée'"
                        >caution traitée</b-badge
                      >
                      <b-badge
                        variant="danger"
                        v-if="data.item.etat == 'non réglé'"
                        >caution non traitée</b-badge
                      >
                    </template>
                    <template #cell(action)="row">
                      <b-button size="sm" @click.prevent="editCaution(row.item)"
                        ><i class="ik ik-edit-2"></i
                      ></b-button>
                      <b-button
                        size="sm"
                        variant="danger"
                        class="ml-2"
                        @click.prevent="deleteCaution(row.item)"
                        ><i class="ik ik-trash-2"></i
                      ></b-button>
                    </template>
                    <template #row-details="row">
                      <b-card class="m-0">
                        <div class="d-flex justify-content-end">
                          <a
                            href="#"
                            @click="row.toggleDetails"
                            class="text-danger"
                            ><i class="fa fa-times"></i
                          ></a>
                        </div>
                        <dl>
                          <dt>
                            {{ $t("data.occupation_description_charge") }}
                          </dt>
                          <dd>
                            <p>{{ row.item.observation }}</p>
                          </dd>
                        </dl>
                      </b-card>
                    </template>
                  </b-table>
                </b-overlay>
              </b-container>
            </div>
            <!-- Contrat -->
            <div id="contrat" v-show="section == 'contrats'">
              <!-- <span> ok wwwww </span> -->
              <contrat-occupation :occupation="occupation" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="menu-options"
      v-show="moreOptionVisible"
      @click="closeMenuVisible()"
    >
      <ul @click.stop="">
        <li @click="chargeCompteVisible = true">
          <div>
            <i class="ik ik-credit-card"></i>
            <span>Recharger le compte</span>
          </div>
          <div>
            <i class="ik ik-chevron-right"></i>
          </div>
        </li>
        <li @click="genererLoyerVisible = true">
          <div>
            <i class="ik ik-layers"></i>
            <span>Generer les loyers</span>
          </div>
          <div>
            <i class="ik ik-chevron-right"></i>
          </div>
        </li>
        <li @click="genererChargeVisible = true">
          <div>
            <i class="ik ik-file-text"></i>
            <span>Générer les charges</span>
          </div>
          <div>
            <i class="ik ik-chevron-right"></i>
          </div>
        </li>
        <li @click="createChargeVisible = true">
          <div>
            <i class="ik ik-file-plus"></i>
            <span>Ajouter une charge</span>
          </div>
          <div>
            <i class="ik ik-chevron-right"></i>
          </div>
        </li>
        <li @click="createCautionVisible = true">
          <div>
            <i class="ik ik-folder-plus"></i>
            <span>Ajouter une caution</span>
          </div>
          <div>
            <i class="ik ik-chevron-right"></i>
          </div>
        </li>
        <li @click="clotureBailVisible = true">
          <div>
            <i class="ik ik-file-minus"></i>
            <span>Clôturer le bail</span>
          </div>
          <div>
            <i class="ik ik-chevron-right"></i>
          </div>
        </li>
        <li @click="createBailVisible = true">
          <div>
            <i class="ik ik-file-minus"></i>
            <span>editer le bail</span>
          </div>
          <div>
            <i class="ik ik-file-minus"></i>
          </div>
        </li>
        <button @click="closeMenuVisible()">Fermer</button>
      </ul>
    </div>
    <!--MODAL POUR AJOUTER OU MODIFIER UNE CHARGE-->
    <b-modal id="chargeForm" size="sm" hide-footer no-close-on-backdrop>
      <template #modal-title>
        <span class="ml-4 text-form-charge">{{ title }}</span>
      </template>
      <charge-form
        :action="action"
        :occupation="occupation"
        @added="handleAddCharge"
      />
    </b-modal>

    <!--MODAL AJOUTER UNE ACTIVITE -->
    <!--<b-modal id="ajouterActivite" size="sm" :title="$t('data.occupation_recharger_compte')" @hidden="resetForm" no-close-on-backdrop hide-footer>
            <div>
                <b-form-group label="Estimamtion du montant">
                    <vue-numeric class="form-control" :placeholder="$t('data.occupation_montant_de_la_recharge_exemple')" required separator=" " v-model="activite.montant"></vue-numeric>
                </b-form-group>
                <b-form-group label="Desription">
                    <b-form-input type="text"  placeholder="remplacement vachette" v-model="activite.description" />
                </b-form-group>
                <b-form-group label="">
                    <div
                    id="my-strictly-unique-vue-upload-multiple-image"
                    style="display: flex; justify-content: center;"
                    >
                        <vue-upload-multiple-image
                        @upload-success="uploadImageSuccess"
                        @before-remove="beforeRemove"
                        @edit-image="editImage"
                        :data-images="images"
                        :dragText="$t('data.logement_dragText')"
                        :browseText="$t('data.logement_browseText')"
                        idUpload="myIdUpload"
                        editUpload="myIdEdit"
                        ></vue-upload-multiple-image>
                    </div>
                </b-form-group>
                
                <b-button class="float-right mt-2" variant="danger" :disabled="submitted" @click.prevent="runPaiement">{{$t('data.cite_valider_cite')}}  <b-spinner v-if="submitted" small /></b-button>
            </div>
        </b-modal>-->

    <!--MODAL POUR AJOUTER OU MODIFIER OU TRAITER UNE CAUTION-->
    <b-modal id="cautionForm" size="sm" hide-footer no-close-on-backdrop>
      <template #modal-title>
        <span class="ml-2 text-form-charge">{{ titleCaution }}</span>
      </template>
      <caution-form
        :action="actionCaution"
        :occupation="occupation"
        :cautionToEdit="caution"
        @caution-added="handleAddCaution"
        @caution-edited="handleEditCaution"
      />
    </b-modal>

    <!--MODAL RECHARGER UN COMPTE -->
    <!--<b-modal id="rechargeCompte" size="sm" :title="$t('data.occupation_recharger_compte')" @hidden="resetForm" no-close-on-backdrop hide-footer>-->
    <b-modal
      id="rechargeCompte"
      :title="$t('data.occupation_recharger_compte')"
      @hidden="resetForm"
      no-close-on-backdrop
      hide-footer
    >
      <recharge-compte-form
        @payed="handleRecharge"
        :comptes="occupation.comptes"
      />
    </b-modal>
    <!--MODAL CREER UN COMPTE -->
    <b-modal
      id="creerCompte"
      size="sm"
      :title="$t('data.occupation_creer_compte_pour_occupation')"
      @hidden="resetForm"
      @ok="createCompte"
    >
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" @click="cancel()">{{
          $t("data.occupation_recharger_compte_annuler")
        }}</b-button>
        <b-button size="sm" variant="danger" @click="ok()" :disabled="submitted"
          >Valider <b-spinner v-if="submitted" small
        /></b-button>
      </template>
      <b-form-group :label="$t('data.occupation_creer_compte_label_compte')">
        <b-form-input
          v-model="creerCompte.typeCompte"
          :placeholder="$t('data.occupation_creer_compte_label_compte_exemple')"
        />
      </b-form-group>
      <b-form-group :label="$t('data.occupation_creer_compte_solde_initial')">
        <b-form-input
          type="number"
          min="1"
          :placeholder="
            $t('data.occupation_creer_compte_solde_initial_exemple')
          "
          v-model="creerCompte.solde"
        />
      </b-form-group>
    </b-modal>

    <!--MODAL POUR PAYER UNE CHARGE -->
    <b-modal
      id="payerCharge"
      size="sm"
      :title="$t('data.occupation_payer_charge_titre')"
      @hidden="resetForm"
      no-close-on-backdrop
      hide-footer
    >
      <payer-charge-form
        :idCharge="payerCharge.idCharge"
        :montant="checkMontantToSend"
        @payed="handlePayerCharge"
      />
    </b-modal>

    <!--MODAL POUR PAYER UN INDEXE -->
    <b-modal
      id="payerIndexe"
      size="sm"
      :title="$t('data.occupation_reglement_indexe_titre')"
      @hidden="resetForm"
      no-close-on-backdrop
      hide-footer
    >
      <payer-indexe-form
        :idIndexe="payerIndexe.idIndexe"
        :montant="payerIndexe.montant"
        @payed="handlePayerIndexe"
      />
    </b-modal>

    <!--MODAL POUR PAYER UN LOYER -->
    <b-modal
      id="payerLoyer"
      size="sm"
      :title="$t('data.occupation_reglement_loyer_titre')"
      @hidden="resetForm"
      no-close-on-backdrop
      hide-footer
    >
      <payer-loyer-form
        :idLoyer="payerLoyer.idLoyer"
        :montant="payerLoyer.montant"
        @payed="handlePayerLoyer"
      />
    </b-modal>

    <!-- MODAL POUR REGLER LES IMPAYES -->
    <b-modal
      id="reglerImpayer"
      :title="$t('data.regler_impayer')"
      no-close-on-backdrop
      @hidden="resetForm"
      hide-footer
    >
      <b-form-group label="Selectionnez une période" class="mb-2">
        <div class="d-flex">
          <b-form-select
            class="form-control mr-1"
            size="sm"
            v-model="periode.mois"
            :options="mois"
          />
          <b-form-select
            class="form-control ml-1"
            size="sm"
            v-model="periode.annee"
            :options="annees"
          />
        </div>
      </b-form-group>
      <div v-if="!empty(periodeCourante)">
        <payer-impayer-form
          :occupation="occupation"
          :periodeCourante="periodeCourante"
          @payed="handleReglerImpayer"
        />
      </div>
    </b-modal>

    <!-- MODALE POUR AFFICHER LES DETAILS D'UN HABITANT -->
    <div
      class="modal fade edit-layout-modal"
      id="editLayoutItem"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editLayoutItemLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editLayoutItemLabel">
              <span v-if="elementDetails == 'habitant'"
                >{{ $t("data.habitant_form_dossier_de") }}
                <b>{{ occupation.locataire.nomLocataire }}</b></span
              >
              <span v-if="elementDetails == 'logement'"
                >{{ $t("data.logement_details_logement") }}
                <b>{{ occupation.logement.refLogement }}</b></span
              >
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pt-2">
            <details-habitant
              v-if="elementDetails == 'habitant'"
              :idLocataire="occupation.locataire.idLocataire"
            />
            <details-logement
              v-if="elementDetails == 'logement'"
              :idLogement="occupation.logement.idLogement"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL POUR GENERER LES LOYERS -->
    <b-modal
      size="sm"
      id="generateLoyers"
      centered
      title="Générer les loyers"
      no-close-on-backdrop
      @ok="generateLoyers"
      @hidden="resetForm"
      ok-only
      ok-variant="danger"
      ok-title="Valider"
      :ok-disabled="!allowed"
    >
      <b-form-group label="Selectionnez une période">
        <div class="d-flex">
          <b-form-select
            class="form-control mr-1"
            v-model="periode.mois"
            :options="mois"
          />
          <b-form-select
            class="form-control ml-1"
            size="sm"
            v-model="periode.annee"
            :options="annees"
          />
        </div>
      </b-form-group>
      <b-alert v-if="!allowed" show variant="info" class="text-center">
        <i class="fa fa-exclamation-triangle fa-3x"></i><br />
        <span class="h4"
          >Cette occupation n'était pas encore créée à cette période</span
        >
      </b-alert>
    </b-modal>

    <!-- MODAL POUR GENERER LES CHARGES -->
    <b-modal
      size="sm"
      id="generateCharges"
      title="Générer les charges répétitives"
      no-close-on-backdrop
      @ok="generateCharges"
      @hidden="resetForm"
      ok-only
      ok-variant="danger"
      ok-title="Valider"
      :ok-disabled="!allowed"
    >
      <b-form-group label="Selectionnez une période">
        <div class="d-flex">
          <b-form-select
            class="form-control mr-1"
            size="sm"
            v-model="periode.mois"
            :options="mois"
          />
          <b-form-select
            class="form-control ml-1"
            size="sm"
            v-model="periode.annee"
            :options="annees"
          />
        </div>
      </b-form-group>
      <b-alert v-if="!allowed" show variant="info" class="text-center">
        <i class="fa fa-exclamation-triangle fa-3x"></i><br />
        <span class="h4"
          >Cette occupation n'était pas encore créée à cette période</span
        >
      </b-alert>
    </b-modal>

    <!-- CLOTURER LE BAIL -->
    <b-modal
      size="md"
      id="closeOccupation"
      title="Cloture du bail"
      no-close-on-backdrop
      @ok="closeOccupation"
      @hidden="resetForm"
      ok-variant="danger"
      ok-title="Oui"
      cancel-title="Non"
    >
      <h5 class="text-center mb-4">
        Etes-vous sûr de vouloir mettre un terme à ce bail ?
      </h5>
      <b-row>
        <b-col cols="12" lg="6">
          <b-form-group label="Selectionnez la date de fin du bail">
            <date-picker
              v-model="dateClotureBail"
              format="dddd, DD MMMM YYYY"
              valueType="YYYY-MM-DD"
              class="w-100"
              :clearable="false"
              :disabled-date="(date) => date > new Date()"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
          <b-form-group label="Caution remboursée">
            <b-form-input type="number" v-model="cautionRemboussee" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Fiche d'etat des lieux de sortie">
            <file-selector
              accept-extensions=".pdf"
              @changed="handleFicheEtatLieu"
            >
              <b-button variant="outline-danger">
                Selectionnez la fiche d'état des lieux
              </b-button>
            </file-selector>
          </b-form-group>
          <div class="mt-2 text-center" v-if="!empty(ficheEtatLieu.src)">
            <b-badge class="mb-1">{{ ficheEtatLieu.name }}</b-badge>
            <object
              :data="ficheEtatLieu.src"
              class="w-100 border-0"
              style="max-height: 200px"
            ></object>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <GenererLoyer
      v-show="genererLoyerVisible"
      :idOccupation="occupation.idOccupation"
      :debutOccupation="this.occupation.dateDeb"
      @close="genererLoyerVisible = false"
    />
    <GenererCharge
      v-show="genererChargeVisible"
      :idOccupation="occupation.idOccupation"
      :debutOccupation="this.occupation.dateDeb"
      @close="genererChargeVisible = false"
    />
    <AjouterCaution
      v-show="createCautionVisible"
      :idOccupation="occupation.idOccupation"
      @close="createCautionVisible = false"
    />
    <AjouterCharge
      v-show="createChargeVisible"
      :action="action"
      :occupation="occupation"
      @close="createChargeVisible = false"
      @added="handleAddCharge"
    />
    <RechargerCompte
      v-show="chargeCompteVisible"
      @close="chargeCompteVisible = false"
      @payed="handleRecharge"
      :comptes="occupation.comptes"
    />
    <CloturerBail
      v-show="clotureBailVisible"
      :idOccupation="occupation.idOccupation"
      @close="clotureBailVisible = false"
    />
    <createBail
      v-show="createBailVisible"
      :action="action"
      :provenance="provenance"
      :editOccupation="occupation"
      @close="createBailVisible = false"
    />
  </div>
</template>

<script>
import chargeForm from "@/views/finances/charges/chargeForm.vue";
import cautionForm from "@/views/finances/cautions/cautionForm.vue";
import contratOccupation from "./contratOccupation.vue";
import PayerImpayerForm from "@/components/form/PayerImpayerForm.vue";
import PayerLoyerForm from "@/components/form/PayerLoyerForm.vue";
import PayerChargeForm from "@/components/form/PayerChargeForm.vue";
import PayerIndexeForm from "@/components/form/PayerIndexeForm.vue";
import RechargeCompteForm from "@/components/form/RechargeCompteForm.vue";
import DetailsHabitant from "@/views/gestion-immobiliere/habitants/DetailsHabitant.vue";
import DetailsLogement from "@/components/_patrimoine/DetailsLogement.vue";
import { mapGetters } from "vuex";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/fr";

import { calcul_consommation_indexe } from "../../../helpers/loyers";
const php = require("phpjs");
const {
  mois: _mois,
  annees: _annees,
  periodeCourante: _periodeCourante,
} = require("../../../helpers/date");

import Vue from "vue";
import VueNumeric from "vue-numeric";

Vue.use(VueNumeric);

import VueUploadMultipleImage from "vue-upload-multiple-image";
import GenererLoyer from "../../../components/form/genererLoyer.vue";
import GenererCharge from "../../../components/form/genererCharge.vue";
import AjouterCaution from "../../../components/form/ajouterCaution.vue";
import AjouterCharge from "../../../components/form/ajouterCharge.vue";
import RechargerCompte from "../../../components/form/rechargerCompte.vue";
import CloturerBail from "../../../components/form/cloturerBail.vue";
import createBail from "../../../components/form/createBail.vue";

export default {
  data() {
    return {
      moreOptionPop: false,
      section: "generalites",
      moreOption: false,
      block1: false,
      block2: false,
      genererLoyerVisible: false,
      genererChargeVisible: false,
      createCautionVisible: false,
      createChargeVisible: false,
      chargeCompteVisible: false,
      clotureBailVisible: false,
      createBailVisible:false,
      /*propriétes activites */
      activite: {
        descrition: "",
        photos: "",
        montant: "",
      },
      images: [],

      submitted: false,
      creerCompte: { typeCompte: null, solde: 0 },
      payerCharge: { montant: 0, idCharge: null },
      payerIndexe: { montant: 0, idIndexe: null },
      payerLoyer: { montant: 0, idLoyer: null },

      periode: { annee: null, mois: null },

      elementDetails: "",
      tab_indexe: "eau",
      title: "Ajouter une charge",
      titleCaution: "Ajouter une caution",
      showOverlayTable: false,

      charges: [],
      loyers: [],
      indexes_: [],
      depots: [],
      cautions: [],
      caution: null,
      action: "add",
      actionCaution: "add",
      provenance:'',
      ficheEtatLieu: { name: "", size: "", src: "", file: null },
      cautionRemboussee: this.occupation.caution,
      dateClotureBail: this.$dayjs().format("YYYY-MM-DD HH:mm"),
    };
  },
  props: {
    occupation: { type: Object, required: true },
    pos: { type: Number, required: true },
    total: { type: Number, required: true },
    //etat menu mobile
    moreOptionVisible: { type: Boolean, required: true, default: false },
  },
  components: {
    chargeForm,
    cautionForm,
    contratOccupation,
    PayerImpayerForm,
    PayerLoyerForm,
    PayerChargeForm,
    PayerIndexeForm,
    RechargeCompteForm,
    DetailsHabitant,
    DetailsLogement,
    DatePicker,
    VueUploadMultipleImage,
    GenererLoyer,
    GenererCharge,
    AjouterCaution,
    AjouterCharge,
    RechargerCompte,
    CloturerBail,
    createBail
  },

  computed: {
    ...mapGetters(["user", "hasAccess"]),
    canDeleteDepot() {
      return this.hasAccess("delete_depot");
    },
    canEditDepot() {
      return this.hasAccess("edit_depot");
    },
    canViewImpactDepot() {
      return this.hasAccess("view_impact_depot");
    },
    canViewCautionsOccupation() {
      return this.hasAccess("view_cautions_occupation");
    },
    canViewActivitesOccupation() {
      return this.hasAccess("view_activites_occupation");
    },
    canViewContratOccupation() {
      return this.hasAccess("view_contrat_occupation");
    },
    canViewDepotsOccupation() {
      return this.hasAccess("list_depots_occupation");
    },
    canViewIndexesOccupation() {
      return this.hasAccess("list_indexes_occupation");
    },
    canViewLoyersOccupation() {
      return this.hasAccess("list_loyers_ocupation");
    },
    canViewChargesOccupation() {
      return this.hasAccess("list_charges_ocupation");
    },

    canRechargeCompte() {
      return this.hasAccess("recharge_compte_occupation");
    },
    canReglerImpayes() {
      return this.hasAccess("regler_impayes_occupation");
    },
    canCreateCompte() {
      return this.hasAccess("ajouter_compte_occupation");
    },
    canAddCharge() {
      return this.hasAccess("add_charge_occupation");
    },
    canCloseEtatCharge() {
      return this.hasAccess("close_charge_ocupation");
    },
    canPayCharge() {
      return this.hasAccess("pay_charge_ocupation");
    },
    canGenerateCharges() {
      return this.hasAccess("generate_charge_occupation");
    },
    canGenerateLoyers() {
      return this.hasAccess("generate_loyers_occupation");
    },
    canAddActivite() {
      return this.hasAccess("add_activite_occupation");
    },
    canAddCaution() {
      return this.hasAccess("add_caution_occupation");
    },
    canEditOccupation() {
      return this.hasAccess("edit_occupation");
    },
    canCloseOccupation() {
      return this.hasAccess("cloturer_bail");
    },
    canCreateoccupation() {
      return this.hasAccess("add_occupation");
    },
    devise() {
      return /* this.user.preferences.devise ||  */ "F";
    },
    mois() {
      return _mois(this.periode.annee).map((elt) => ({
        ...elt,
        text: php.ucfirst(elt.text),
      }));
    },
    periodeCourante() {
      return _periodeCourante(this.periode);
    },
    annees: () => _annees(),
    allowed() {
      return (
        this.$dayjs(this.periodeCourante).diff(
          this.$dayjs(this.occupation.dateDeb).format("YYYY-MM") + "-01",
          "month"
        ) >= 0
      );
    },
    indexes() {
      if (
        this.tab_indexe == "eau" &&
        this.occupation.modeEau == "forfait" &&
        this.occupation.modeEnergie == "index"
      ) {
        this.tab_indexe = "energie";
      }
      return this.indexes_type(this.tab_indexe); /* .sort((a, b) => {
                  return this.$dayjs().diff(b.periode) - this.$dayjs().diff(a.periode)
            }) */
    },
    totalImpayes() {
      return this.occupation.impayerTotal;
    },
    localisation() {
      const logement = this.occupation.logement;
      if (!php.empty(logement.adresse)) {
        return logement.adresse.ville + " " + logement.adresse.pays;
      }
      if (!php.empty(logement.batiment)) {
        if (!php.empty(logement.batiment.adresse)) {
          return (
            logement.batiment.adresse.ville +
            " " +
            logement.batiment.adresse.pays
          );
        }
      }
      return "";
    },
    checkMontantToSend() {
      let montantTosend = this.payerCharge.montant;
      if (this.payerCharge.montant > this.occupation.solde) {
        return this.occupation.solde;
      }
      return montantTosend;
    },

    soldeOccupation() {
      return this.occupation.comptes.reduce((acc, curr) => acc + curr.solde, 0);
    },
  },
  watch: {
    section(value) {
      if (value == "loyers") {
        this.getLoyers();
      }
      if (value == "charges") {
        this.getCharges();
      }
      if (value == "cautions") {
        this.getCautions();
      }
      if (value == "depots") {
        this.getDepots();
      }
      if (value == "indexes") {
        this.getIndexes();
      }
    },
    "occupation.idOccupation"() {
      this.resetOccupation();
    },
  },
  mounted() {
    const now = this.$dayjs();
    this.periode.annee = now.format("YYYY");
    this.periode.mois = now.format("MM") - 1;

    setTimeout(() => {
      $("#editLayoutItem").modal("hide");
      $(".modal-backdrop").remove();
    }, 100);
  },
  updated() {},
  methods: {
    setEdition(){
      this.action='edit'
      this.createBailVisible = true
    },
    empty: (val) => php.empty(val),

    indexes_type(type) {
      return this.indexes_.filter((elt) => elt.typeIndexe == type);
    },
    closeMenuVisible() {
      this.$emit("closeMobileMenuVisible");
    },
    editCaution(caution) {
      this.actionCaution = "edit";
      this.caution = caution;
      this.titleCaution = "edition de caution";
      this.$bvModal.show("cautionForm");
    },
    deleteCaution(caution) {
      App.confirm(
        `Voullez vous vraiment supprimer cette caution <br><span>type de caution : </span> <b>${caution.type}<br><span>Montant :</span>${caution.montant} </b>  ?`,
        {
          confirm: () => {
            axios.delete(`cautions/${caution.idCaution}`).then((response) => {
              if (!response.success) {
                return App.notifyError(response.message);
              }
              this.cautions = this.cautions.filter(
                (elt) => elt.idCaution != caution.idCaution
              );
              return App.notifySuccess(response.message);
            });
          },
        }
      );

      console.log("caution à supprimer", caution);
    },
    deleteDepot(depot) {
      console.log("dépot", depot);
      App.confirm(
        `Voullez vous vraiment supprimer le dépot de <b>${
          depot.montant
        }<br></b> éffectué le <b><span>${this.$dayjs(depot.dateDepot).format(
          "dddd, DD MMM YYYY"
        )}</span>  à  ${this.$dayjs(depot.dateDepot).format("HH:mm")} </b> ?`,
        {
          confirm: () => {
            axios.delete(`depots/${depot.idDepot}`).then((response) => {
              if (!response.success) {
                return App.notifyError(response.message);
              }
              this.depots = this.depots.filter(
                (elt) => elt.idDepot != depot.idDepot
              );
              return App.notifySuccess(response.message);
            });
          },
        }
      );
    },
    /**
     * changer le statut d'une charge
     */
    changeState(charge) {
      this.showOverlayTable = true;
      axios
        .put("/charges/" + charge.idCharge + "/change-state")
        .then((response) => {
          if (!response.success) {
            this.showOverlayTable = false;
            return App.alertError(response.message);
          }
          axios
            .get("occupations/" + charge.idOccupation + "/charges")
            .then((response) => response.result || [])
            .then((charges) => {
              this.charges = charges;
            });
          this.showOverlayTable = false;

          return App.notifySuccess(response.message);
        });
    },
    /**
     * cloturer un bail en cours
     */
    closeOccupation(bvModalEvt) {
      bvModalEvt.preventDefault();
      const fd = new FormData();
      fd.append("etatDeLieuSortie", this.ficheEtatLieu.file);
      fd.append("dateFin", this.dateClotureBail);
      fd.append("cautionRemboussee", this.cautionRemboussee);

      axios
        .put("occupations/" + this.occupation.idOccupation + "/close", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$bvModal.hide("closeOccupation");
          this.occupation.dateFin = this.dateClotureBail;
          return App.notifySuccess(response.message);
        })
        .catch((error) => {
          this.submitted = false;
          return App.alertError(error.message || "Erreur survenue");
        });
    },
    async handleFicheEtatLieu(files) {
      const list = Array.from(files);
      for (const file of list) {
        this.ficheEtatLieu = {
          file,
          name: file.name,
          size: file.size,
          src: await this.loadImgAsDataUrl(file),
        };
      }
    },
    async loadImgAsDataUrl(file) {
      return await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => resolve(e.target.result);
      });
    },

    /**
     *
     */
    createCompte(bvModalEvt) {
      bvModalEvt.preventDefault();

      if (
        php.empty(this.creerCompte.typeCompte) ||
        (php.empty(this.creerCompte.solde) && this.creerCompte.solde != 0)
      ) {
        return App.error("Veuillez remplir tous les champs du formulaire");
      }
      const data = {
        ...this.creerCompte,
        idOccupation: this.occupation.idOccupation,
      };
      this.submitted = true;
      axios
        .post("comptes", data)
        .then((response) => {
          this.occupation.comptes.push(response.result);
          this.$bvModal.hide("creerCompte");
          return App.notifySuccess(response.message);
        })
        .catch((error) => {
          this.submitted = false;
          return App.alertError(error.message);
        });
    },

    /**
     * Ouvre la modale pour payer une charge
     */
    buyCharge(charge) {
      this.payerCharge.idCharge = charge.idCharge;
      this.payerCharge.montant = charge.montant - (charge.montantPayer || 0);
      if (this.payerCharge.montant > this.occupation.solde) {
        this.payerCharge.montant = this.occupation.solde;
      }
      this.$bvModal.show("payerCharge");
    },
    /**
     * Ouvre la modale pour payer un indexe
     */
    buyIndexe(indexe, type) {
      this.payerIndexe.idIndexe = indexe.idIndexe;
      this.payerIndexe.montant = indexe.montant - (indexe.avance || 0);
      if (this.payerIndexe.montant > this.occupation.solde) {
        this.payerIndexe.montant = this.occupation.solde;
      }
      this.$bvModal.show("payerIndexe");
    },
    /**
     * Ouvre la modale pour payer un loyer
     */
    buyLoyer(loyer) {
      this.payerLoyer.idLoyer = loyer.idLoyer;
      this.payerLoyer.montant = loyer.montant - (loyer.montantPayer || 0);
      if (this.payerLoyer.montant > this.occupation.solde) {
        this.payerLoyer.montant = this.occupation.solde;
      }
      this.$bvModal.show("payerLoyer");
    },

    async handleReglerImpayer() {
      this.charges = await axios
        .get("occupations/" + this.occupation.idOccupation + "/charges")
        .then((res) => res.result || []);
      this.loyers = await axios
        .get("occupations/" + this.occupation.idOccupation + "/loyers")
        .then((res) => res.result || []);
      this.indexes_ = await axios
        .get("occupations/" + this.occupation.idOccupation + "/indexes")
        .then((res) => res.result || []);

      this.$bvModal.hide("reglerImpayer");
      this.$emit("change", this.occupation);
    },
    async handlePayerCharge() {
      this.charges = await axios
        .get("occupations/" + this.occupation.idOccupation + "/charges")
        .then((res) => res.result || []);
      this.$emit("change", this.occupation);
      this.$bvModal.hide("payerCharge");
    },
    async handlePayerLoyer() {
      this.loyers = await axios
        .get("occupations/" + this.occupation.idOccupation + "/loyers")
        .then((res) => res.result || []);
      this.$emit("change", this.occupation);
      this.$bvModal.hide("payerLoyer");
    },
    async handlePayerIndexe() {
      this.indexes_ = await axios
        .get("occupations/" + this.occupation.idOccupation + "/indexes")
        .then((res) => res.result || []);
      this.$emit("change", this.occupation);
      this.$bvModal.hide("payerIndexe");
    },
    async handleRecharge() {
      this.depots = await axios
        .get("occupations/" + this.occupation.idOccupation + "/depots")
        .then((res) => res.result || []);
      this.charges = await axios
        .get("occupations/" + this.occupation.idOccupation + "/charges")
        .then((res) => res.result || []);
      this.loyers = await axios
        .get("occupations/" + this.occupation.idOccupation + "/loyers")
        .then((res) => res.result || []);
      this.indexes_ = await axios
        .get("occupations/" + this.occupation.idOccupation + "/indexes")
        .then((res) => res.result || []);

      this.$emit("change", this.occupation);
      this.$bvModal.hide("rechargeCompte");
      this.section = "depots";
    },
    async handleAddCharge() {
      this.charges = await axios
        .get("occupations/" + this.occupation.idOccupation + "/charges")
        .then((res) => res.result || []);
      this.$bvModal.hide("chargeForm");
      this.$emit("change", this.occupation);
      this.section = "charges";
    },
    callCautionForm() {
      this.actionCaution = "add";
      this.titleCaution = "Ajouter une caution";
      this.$bvModal.show("cautionForm");
    },
    // traitement de l'ajout d'une nouvelle caution
    handleAddCaution(newCaution) {
      console.log("entrée ajout nouvelle caution ", newCaution);
      this.$bvModal.hide("cautionForm");
      this.cautions.unshift(newCaution);
      this.$emit("change", this.occupation);
      $('[data-toggle="pill"][href="#cautions"]').click();
    },
    handleEditCaution() {
      this.$bvModal.hide("cautionForm");
      this.getCautions();
    },

    /**
     * Genere les charges repetitive de l'occupation
     */
    generateCharges(bvModalEvt) {
      bvModalEvt.preventDefault();
      axios
        .post("charges/auto-generate", {
          periode: this.periodeCourante,
          idOccupation: this.occupation.idOccupation,
        })
        .then(async (response) => {
          this.charges = await axios
            .get("occupations/" + this.occupation.idOccupation + "/charges")
            .then((res) => res.result || []);
          App.notifySuccess(response.message);
          this.$bvModal.hide("generateCharges");
          this.$emit("change", this.occupation);
          this.section = "charges";
        })
        .catch((error) => {
          return App.alertError(error.message);
        });
    },
    /**
     * Genere le loyer de l'occupation
     */
    generateLoyers(bvModalEvt) {
      bvModalEvt.preventDefault();
      axios
        .post("loyers/generate", {
          periode: this.periodeCourante,
          idOccupation: this.occupation.idOccupation,
        })
        .then(async (response) => {
          this.loyers = await axios
            .get("occupations/" + this.occupation.idOccupation + "/loyers")
            .then((res) => res.result || []);
          App.notifySuccess(response.message);
          this.$bvModal.hide("generateLoyers");
          this.$emit("change", this.occupation);
          this.section = "loyers";
        })
        .catch((error) => {
          return App.alertError(error.message);
        });
    },

    resetForm() {
      this.recharge = {
        ...this.recharge,
        idCompte: null,
        montant: null,
        description: null,
      };
      this.creerCompte = { typeCompte: null, solde: 0 };
      this.payerCharge = { montant: 0, idCharge: null };
      this.payerIndexe = { montant: 0, idIndexe: null };
      this.payerLoyer = { montant: 0, idLoyer: null };
      this.submitted = false;
      this.dateClotureBail = this.$dayjs().format("YYYY-MM-DD HH:mm");
    },
    //traitement affichage du contrat de bail
    showContrat() {
      console.log("contrat", this.occupation);
      this.$emit("showDetails", this.occupation);
    },

    showDetails(element) {
      this.elementDetails = element;
      $("#editLayoutItem").modal("show");
    },

    /*debut methodes liées à la gestion des activitées */
    uploadImageSuccess(formData, index, fileList) {
      this.activite.photos = [];
      console.log("taille Totale", fileList.length - 1, "formaData", formData);
      for (let i = 0; i < fileList.length; i++) {
        //console.log("essai",fileList[i].path)
        this.activite.photos.push(fileList[i].path);
      }
      console.log("photosTaille", this.photos.length);
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("voulez vous supprimer cette image");
      if (r == true) {
        done();
      } else {
        console.log("erreur");
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", fileList);
    },

    getLoyers() {
      if (php.empty(this.loyers)) {
        this.showOverlayTable = true;
        axios
          .get("occupations/" + this.occupation.idOccupation + "/loyers")
          .then((response) => response.result || [])
          .then((result) => {
            this.loyers = result;
            this.showOverlayTable = false;
          });
      }
    },
    getCharges() {
      if (php.empty(this.charges)) {
        this.showOverlayTable = true;
        axios
          .get("occupations/" + this.occupation.idOccupation + "/charges")
          .then((response) => response.result || [])
          .then((result) => {
            this.charges = result;
            this.showOverlayTable = false;
          });
      }
    },
    getCautions() {
      //if (php.empty(this.cautions)) {
      this.showOverlayTable = true;
      axios
        .get("occupations/" + this.occupation.idOccupation + "/cautions")
        .then((response) => response.result || [])
        .then((result) => {
          this.cautions = result;
          console.log("cautions", this.cautions);
          this.showOverlayTable = false;
        });
      //}
    },
    getIndexes() {
      if (php.empty(this.indexes_)) {
        this.showOverlayTable = true;
        axios
          .get("occupations/" + this.occupation.idOccupation + "/indexes")
          .then((response) => response.result || [])
          .then((result) => {
            this.indexes_ = result;
            this.showOverlayTable = false;
          });
      }
    },
    getDepots() {
      if (php.empty(this.depots)) {
        this.showOverlayTable = true;
        axios
          .get("occupations/" + this.occupation.idOccupation + "/depots")
          .then((response) => response.result || [])
          .then((result) => {
            this.depots = result;
            this.showOverlayTable = false;
          });
      }
    },

    resetOccupation() {
      this.section = "generalites";
      this.loyers = [];
      this.indexes_ = [];
      this.depots = [];
      this.charges = [];
    },
  },
};
</script>
<style scoped>
.text-form-charge {
  font-size: 1em;
  color: #212121ef;
  font-weight: 800;
  text-align: center;
  margin-top: 2px;
}
.big-parent {
  display: block;
  width: 100%;
  height: auto;
}
.main-parent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
  border-radius: 8px;
  /* background: teal; */
}
.resume-block {
  background: white;
  width: calc(25% - 10px);
  box-shadow: none;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.06);
}
.resume-block-header {
  display: none;
}
.resume-block .resume-block-item {
  position: relative;
  padding: 10px 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 50px;
  background: white;
  border-radius: 8px;
  transition: 0.3s ease;
  overflow: hidden;
}
.resume-block-item.long {
  height: auto;
}
.resume-block-item .block-item-label {
  margin-bottom: 1rem;
  width: 100%;
  height: auto;
  cursor: pointer;
  height: 50px;
}
.block-item-label span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: #191c22;
  font-size: 1.2rem;
}
.block-item-label span i {
  cursor: pointer;
}
.resume-block-item .block-item-data {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.block-item-data .data-item {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
}
.data-item.allpay {
  background: #efefef;
  border-radius: 5px;
}
.data-item .data-icon {
  display: flex;
  align-items: center;
  height: 100%;
  width: auto;
}
.data-icon i {
  font-size: 1em;
  color: #5c5c5c;
  display: none;
}
.data-icon span {
  font-size: 0.9rem;
  margin-left: 5px;
}
.data-item .data-value {
  font-size: 0.9rem;
}
.data-item.allpay .data-value,
.block-item-data.logement .data-item .data-value {
  font-weight: bold;
  text-align: right;
}
.great-block {
  background: white;
  width: calc(75% - 10px);
  border-radius: 8px;
  height: auto;
}
.resume-block-item button {
  margin: 1rem 0;
  height: 45px;
  border-radius: 8px;
}
.resume-block-item button:hover {
  background: #efefef;
  border: 1px solid #efefef;
  color: #191c22;
}
.resume-block-item button.first {
  margin-top: 50px;
  background: #efefef;
  border: 1px solid #a3a3a3;
  color: #191c22;
}
.resume-block-item button.close-details {
  background: #191c22;
  border: 1px solid #191c22;
  color: white;
}
.resume-block-item .bail-state {
  padding: 10px 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00c451;
  border-radius: 8px;
  color: white;
  font-weight: bold;
}
.navbar {
  padding: 0 2% 2px 0;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4rem;
}
.navbar .navbar-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: 4rem;
}
.navbar-left div {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
  font-size: 1rem;
  transition: 0.1s ease-in all;
  cursor: pointer;
}
.navbar-left div.active {
  border-top: #f5365c 3px solid;
  font-weight: bold;
  color: #f5365c;
}
.navbar-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: auto;
  display: block;
  /* background: teal; */
}
.switch-occupation .switch-occupation-item {
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
  font-size: 1rem;
  color: #b8b8b8;
  cursor: pointer;
}
.switch-occupation .barre {
  margin: 0 10px;
  height: 30%;
  background: #acacac;
  width: 1px;
}
.gen-block {
  padding: 0 2%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  justify-content: space-between;
}
.gen-block .gen-block-item {
  margin-bottom: 2rem;
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 6rem;
  border-radius: 8px;
  width: 22%;
  /* border:1px solid #ddd; */
  background: #f5f5f5;
}
.gen-block-item .icon-area {
  display: flex;
  align-items: center;
  height: auto;
  width: 100%;
}
.icon-area .icon-block {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: none;
}
.icon-area span {
  font-size: 1rem;
}
.gen-block-item .value {
  margin-top: 0.8rem;
  font-weight: bold;
  font-size: 1rem;
  text-align: left;
}
.more-options {
  /* position: fixed; */
  /* bottom: 50px;
  right: 50px; */
  height: auto;
  width: auto;
  border-radius: 50%;
}
.more-options .more-btn {
  padding: 0 30px;
  width: auto;
  height: 40px;
  border-radius: 8px;
  background: #191c22;
  color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  font-weight: 600;
  font-size: 1rem;
  animation: appear 0.45s;
}
.more-btn i {
  margin-right: 10px;
}
.more-options .more-option-container {
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
  transform-origin: right;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 350px;
  background: white;
  border-radius: 20px 20px 0 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  animation: appear 0.45s;
  /* display: none; */
  z-index: 10;
}
@keyframes appear {
  0% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.more-option-container .close-more-option {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: auto;
}
.close-more-option div {
  height: auto;
  width: auto;
}
.close-more-option div i {
  font-size: 1.3rem;
  cursor: pointer;
}
.more-option-container .option-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  height: 50px;
  width: 100%;
  transition: 0.25s ease;
  cursor: pointer;
}
.option-item:hover {
  transform: translateX(5px);
}
.more-option-container .option-item i {
  margin-right: 12px;
  font-size: 1rem;
}
.option-item:not(:nth-child(2)) {
  border-top: 1px solid #efefef;
}
@media screen and (max-width: 800px) {
  .main-parent {
    flex-direction: column;
    justify-content: flex-start;
  }
  .resume-block {
    background: transparent;
    width: 100%;
  }
  .great-block {
    width: 100%;
    border-radius: 8px;
    height: auto;
  }
  .navbar {
    padding: 0 2% 2px 0;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 3rem;
  }
  .navbar .navbar-left {
    white-space: nowrap;
    overflow: auto;
  }
  .navbar-left div {
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
    font-size: 1rem;
    transition: 0.1s ease-in all;
    cursor: pointer;
  }
  .navbar-left div.active {
    border-top: #ff7490 5px solid;
    font-weight: bold;
    color: #fff;
    background: #f5365c;
  }
  .gen-block {
    padding: 0;
    /* background:teal; */
  }
  .gen-block .gen-block-item {
    margin-bottom: 2rem;
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: 6rem;
    border-radius: 8px;
    width: 48%;
    /* border:1px solid #ddd; */
    background: #f5f5f5;
  }
}

@media screen and (max-width: 600px) {
  .menu-options {
    position: fixed;
    display: flex;
    align-items: flex-end;
    background: rgba(0, 0, 0, 0.12);
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: 10000;
    animation: appearBottom 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  @keyframes appearBottom {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  .menu-options ul {
    margin: 0;
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: white;
  }
  .menu-options ul li {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 1px solid #eee;
  }
  .menu-options ul li i {
    margin-right: 10px;
  }
  .menu-options ul li div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #454545;
  }
  .menu-options ul button {
    margin-top: 40px;
    height: 45px;
    border: none;
    font-weight: 600;
    border-radius: 8px;
  }
  .navbar-right {
    display: none;
  }
  .navbar {
    padding: 0 2% 2px 0;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
  }
  .navbar .navbar-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: 100%;
  }
  .navbar-left div {
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
    font-size: 1rem;
    transition: 0.1s ease-in all;
    cursor: pointer;
  }
  .navbar-left div:nth-child(1) {
    border-radius: 8px 0 0 0;
  }
}
</style>
