<template>
  <div class="form-popup" @click="clos()">
    <div class="form-container" @click.stop="">
      <div class="error-popup" v-show="incompleteData">
        <i class="ik ik-alert-triangle"></i>
        <span>
          {{ customErrorMsg }}
        </span>
      </div>
      <div class="form-header">
        <span> Générer les loyers </span>
        <i class="ik ik-x clos" @click="clos()"></i>
      </div>
      <div class="form-main">
        <span>Selectionnez une période</span>
        <div class="input-container">
          <div class="input-item">
            <select v-model="periode.annee">
              <option
                v-for="(year, cle) in annees"
                :key="cle"
                :value="year"
                class="batiment-option"
              >
                <span class="cite-name">
                  {{ year }}
                </span>
              </option>
            </select>
          </div>
          <div class="input-item">
            <select v-model="periode.mois">
              <option
                v-for="(moi, cle) in mois"
                :key="cle"
                :value="moi.value"
                class="batiment-option"
              >
                <span class="cite-name">
                  {{ moi.text }}
                </span>
              </option>
            </select>
          </div>
        </div>
        <p v-show="!allowed">
          Cette occupation n'était pas encore créée à cette époque.
        </p>
      </div>
      <div class="form-footer">
        <div class="cancel" @click="clos()">
          <OutlinedDarkBtn buttonTitle="Annuler" />
        </div>
        <div class="validate" @click="generateLoyers()" v-show="allowed">
          <FullPinkBtn buttonTitle="Générer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FullPinkBtn from "../atomic/fullPinkBtn.vue";
import OutlinedDarkBtn from "../atomic/outlinedDarkBtn.vue";
const php = require("phpjs");

const {
  mois: _mois,
  annees: _annees,
  periodeCourante: _periodeCourante,
} = require("../../helpers/date");

export default {
  props: {
    idOccupation: { String, required: true },
    debutOccupation: { Date, required: true },
  },
  components: {
    FullPinkBtn,
    OutlinedDarkBtn,
  },
  data: () => ({
    periode: { annee: null, mois: null },
    incompleteData:false,
    customErrorMsg:'Une erreur est survenue'
  }),
  computed: {
    ...mapGetters(["user", "hasAccess"]),
    canGenerateLoyers() {
      return this.hasAccess("generate_loyers_occupation");
    },
    mois() {
      return _mois(this.periode.annee).map((elt) => ({
        ...elt,
        text: php.ucfirst(elt.text),
      }));
    },
    periodeCourante() {
      return _periodeCourante(this.periode);
    },
    annees: () => _annees(),
    allowed() {
      return (
        this.$dayjs(this.periodeCourante).diff(
          this.$dayjs(this.debutOccupation).format("YYYY-MM") + "-01",
          "month"
        ) >= 0
      );
    },
  },
  methods: {
    clos() {
      console.log(this.annee, this.mois, this.idOccupation);
      this.$emit("close");
      this.step = 0;
    },
    generateLoyers() {
      axios
        .post("loyers/generate", {
          periode: this.periodeCourante,
          idOccupation: this.idOccupation,
        })
        .then(async (response) => {
          this.loyers = await axios
            .get("occupations/" + this.idOccupation + "/loyers")
            .then((res) => res.result || []);
          App.notifySuccess(response.message);
          this.incompleteData = false;
          this.clos();
          //   this.$emit("change", this.occupation);
          //   this.section = "loyers";
        })
        .catch((error) => {
            this.customErrorMsg = error.message;
            this.incompleteData = true;
            setTimeout(() => {
              this.incompleteData = false;
            }, 2000);
        //   return App.alertError(error.message);
        });
    },
  },
  mounted() {
    const now = this.$dayjs();
    this.periode.annee = now.format("YYYY");
    this.periode.mois = now.format("MM") - 1;
  },
};
</script>

<style scoped>
.form-popup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  animation: appear2 0.35s;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  z-index: 1500;
  cursor: pointer;
}
@keyframes appear2 {
  0% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.form-popup .form-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 60%;
  max-height: 400px;
  width: 350px;
  max-width: 500px;
  background: white;
  border-radius: 8px;
  cursor: auto;
}
.form-container .form-header {
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #eee;
}
.form-header span {
  font-weight: 600;
  font-size: 1.3rem;
}
.form-header .clos {
  font-size: 1.4rem;
  color: #191c22;
  cursor: pointer;
}
.form-container .form-footer {
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  border-top: 1px solid #eee;
}
.form-container .form-main {
  padding: 5%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 150px);
}
.form-container .error-popup {
  padding-right: 10px;
  position: absolute;
  bottom: 50px;
  left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 90%;
  border-radius: 8px;
  background: white;
  animation: appear 0.3s;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.12);
}
.error-popup i {
  margin: 0 15px;
  font-size: 1.5rem;
}
.error-popup span {
  text-align: left;
  font-size: 0.9rem;
  font-weight: 500;
}
@keyframes appear {
  0% {
    transform: translateY(30px);
    box-shadow: none;
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.12);
  }
}
.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.form-main span {
  font-size: 1.1rem;
  margin-bottom: 10px;
}
.input-container .input-item {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}
.input-item > span {
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 600;
}
.input-item input,
.input-item select {
  padding: 0 5px;
  height: 45px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
  outline: none;
  background: transparent;
}
.input-item select option {
  padding: 10px 0;
  /* height: 45px; */
}
.validate {
  width: calc(60% - 5px);
}
.cancel {
  width: calc(40% - 5px);
}
</style>