<template>
  <div>
    <b-alert variant="info" class="text-center" show v-if="!occupation.contrat.contenu">
      <i class="fa fa-exclamation-triangle fa-3x"></i>
      <br />
      <span class="h4 d-inline-flex ml-2">{{
        $t("data.contratoccupation_aucun_contrat_pour_occupation")
      }}</span>
    </b-alert>
    <!--<div class="well-display" style="height: 130em;" v-else id="printJS-form">
                <vue-editor :disabled="true" :editorToolbar="customToolbar" v-model="occupation.contrat.contenu" style="height: 120em;"></vue-editor>
                <div class="float-right">
                    <b-button variant="outline-primary" v-b-tooltip.top="$t('data.contratoccupation_imprimer')" @click.prevent="setPrintNew" class="mt-4"><i class="fas fa-print"></i>{{$t('data.contratoccupation_imprimer')}}</b-button>
                </div>
          </div>-->
    <div v-else>
      <!--<h1 class="d-flex justify-content-center align-items-center">test 1</h1>
            <h2>test 2</h2>
            <h3>test 3</h3>
            <h4>test 4</h4>-->
      <printer
        :display="true"
        v-model="print"
        @input="printSingle = false"
        id="print-correspondance"
      >
        <div id="printMe" style="max-height: 100vh">
          <!--<vue-editor class="mb-3" :disabled="true" :editorToolbar="customToolbar" v-model="occupation.contrat.contenu" style="height:90vh"></vue-editor>-->
          <vue-editor
            class="mb-3"
            :disabled="true"
            :editorToolbar="customToolbar"
            v-model="occupation.contrat.contenu"
            style="height: 90vh"
          ></vue-editor>
        </div>
      </printer>
      <!--<vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="false"
                :paginate-elements-by-height="1400"
                filename="fiche_recettes"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="100%"
                :htmlToPdfOptions="htmlToPdfOptions"
 
                @progress="onProgress($event)"
                @beforeDownload="beforeDownload($event)"
                @hasStartedGeneration="hasStartedGeneration()"
                @hasGenerated="hasGenerated($event)"
                ref="html2Pdf"
              >
                <section slot="pdf-content">
                    <vue-editor :disabled="true" :editorToolbar="customToolbar" v-model="occupation.contrat.contenu" style="height: 120em;"></vue-editor>
                </section>
            </vue-html2pdf>-->
      <div class="float-right mb-2">
        <!--<b-button variant="outline-primary" v-b-tooltip.top="$t('data.contratoccupation_imprimer')" @click.prevent="generateReport" class="mt-4"><i class="fas fa-print"></i>{{$t('data.contratoccupation_imprimer')}}</b-button>-->
        <b-button
          variant="outline-primary"
          v-b-tooltip.top="$t('data.contratoccupation_imprimer')"
          @click.prevent="printHtmlToPdf"
          class="mt-4"
          ><i class="fas fa-print"></i
          >{{ $t("data.contratoccupation_imprimer") }}</b-button
        >
        <!--<b-button variant="outline-primary" v-b-tooltip.top="$t('data.contratoccupation_imprimer')" @click.prevent="print = true" class="mt-4"><i class="fas fa-print"></i>{{$t('data.contratoccupation_imprimer')}}</b-button>-->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueHtmlToPaper from "vue-html-to-paper";
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
  ],
};

Vue.use(VueHtmlToPaper, options);
//Vue.use(VueHtmlToPaper);

import { VueEditor } from "vue2-editor";
import Printer from "@/components/PrinterContrat.vue";
import printJS from "print-js";
export default {
  components: {
    VueEditor,
    Printer,
  },
  props: {
    occupation: { type: Object, required: true },
  },
  data: () => ({
    print: false,
    customToolbar: [[]],
    htmlToPdfOptions: {
      margin: [10, 5, 10, 5],

      filename: `contrat_de_bail.pdf`,

      image: {
        type: "jpeg",
        quality: 2,
      },

      enableLinks: false,

      html2canvas: {
        scale: 4,
        logging: true,
        useCORS: false,
      },

      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "portrait",
      },
    },
  }),
  computed: {},
  mounted() {},
  methods: {
    async printHtmlToPdf() {
      // Pass the element id here
      await this.$htmlToPaper("printMe");
    },

    setPrintNew() {
      printJS("printJS-form", "html");
    },
    setPrint() {
      this.print = true;
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    onProgress(event) {
      console.log("event", event);
    },
    hasStartedGeneration() {
      console.log("enter has started generation");
    },
    hasGenerated(event) {
      console.log("methods has generared pdf", event);
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      console.log("entrée before dowload");
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          console.log("totalPages", totalPages);
          for (let i = 1; i <= totalPages; i++) {
            console.log("i", i);
            pdf.setPage(i);
            pdf.setFontSize(12);
            pdf.setTextColor(150);
            pdf.text(
              "Page " + i + " sur " + totalPages,
              pdf.internal.pageSize.getWidth() * 0.88,
              pdf.internal.pageSize.getHeight() - 2.9
            );
          }
        })
        .save();
    },
  },
};
</script>

<style scoped>
/*.display-print-button{
         position:absolute;
         right:4em;
    }*/
</style>