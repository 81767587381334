<template>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-row>
        <b-col>
          <b-form-group
            :label="$t('data.caution_form_montant')"
            :description="$t('data.charge_form_montant_description')"
          >
              <vue-numeric class="form-control" :placeholder="$t('data.charge_form_montant_exemple')" required separator=" " v-model="caution.montant"></vue-numeric>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
            <b-form-group label="Date">
                <date-picker v-model="caution.date" placeholder="Selectionnez une date" format="dddd, DD MMMM YYYY" valueType="YYYY-MM-DD" class="w-100" :clearable="false"/>
            </b-form-group>                        
        </b-col>
      </b-row>

      <b-row>
        <b-col>
            <div class="form-group">
                <label>{{$t('data.caution_form_type_de_caution')}}</label>
                <v-select label="libelle" :options="typesCautions"  v-model="typeCaution" @input="setTypeCaution"></v-select>
            </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
            <div class="form-group">
                <label>etat de la caution</label>
                <v-select label="libelle" :options="etatsCautions"  v-model="etatCaution" @input="setEtatCaution"></v-select>
            </div>
        </b-col>
      </b-row>
        <hr>
        <div class="float-right">
        <b-button @click.prevent="submitModal" variant="danger">{{$t('data.cite_valider_cite')}}</b-button>
    </div>
    </b-overlay>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/fr';
import notif from "@/plugins/notif.js";
const php = require("phpjs");

import Vue from 'vue'
import VueNumeric from 'vue-numeric'

Vue.use(VueNumeric)

export default {
  name: "caution-form",
  data: () => ({
   disabled:false,

    caution: {
      type: "",
      montant: "",
      date: "",
      etat:'non réglée'
    },
    typeCaution:'',
    etatCaution:'',
    commandeAction:"",
    showOverlay: false,
    sendForm: false,
    typesCautions:[
      { 
        libelle : 'eau', 
      },
      { 
        libelle : 'électricité', 
      },
      { 
        libelle : 'loyer', 
      },
    ],
    etatsCautions:[
      { 
        libelle : 'réglée', 
      },
      { 
        libelle : 'non réglé', 
      },
      { 
        libelle : 'remboursée', 
      },
    ]

  }),
  components: {
    DatePicker
  },
  props: {
        action: {type: String, required: true, default: "add"},
        occupation: { type: Object},
        cautionToEdit: { type: Object},
        action:{type: String}
    },
  methods: {
     setTypeCaution(){
      this.caution.type=this.typeCaution.libelle
     },
     setEtatCaution(){
       this.caution.etat=this.etatCaution.libelle;
     },

    resetModal() {
      this.caution = {
        type: '',
        montant: '',
        date: '',
        etat: ''
      };
    },

    //validation formulaire d'ajout/modification d'une caution
    submitModal(bvModalEvt) {
        console.log('données caution', this.caution);
      bvModalEvt.preventDefault();
      if (php.empty(this.caution.montant) || php.empty(this.caution.type)) {
                return App.error('Un montant, une date et la raison de la caution sont obligatoires')
      }
      if (this.caution.montant =='') {
        return App.error('Vous devez spécifier un montant')
      }
      if (this.caution.type =='') {
        return App.error('Vous devez spécifier selectionner le type de caution dont il s\'agit')
      }
      if (this.action == "add") {
        console.log('caution', this.caution)
          this.showOverlay = true;
            axios.post('occupations/'+this.occupation.idOccupation+'/cautions',this.caution).then(response =>{
                  this.resetModal();
                   this.showOverlay = false;
                  this.$emit("caution-added", response.result);
                  return App.notifySuccess(response.message)
                 
            })
            .catch(error => {
                this.showOverlay = false;
                 notif.error(error.message);
            });
  
      }
      if (this.action == "edit") {
          this.showOverlay = true;
            axios.put('cautions/'+this.cautionToEdit.idCaution,this.caution).then(response =>{
                  this.resetModal();
                   this.showOverlay = false;
                  this.$emit("caution-edited", response.result);
                  return App.notifySuccess(response.message)
                 
            })
            .catch(error => {
                this.showOverlay = false;
                 notif.error(error.message);
            });
  
      }
    },


  },
  mounted(){
    if(this.cautionToEdit !=null){
      console.log('caution to edit ',this.cautionToEdit);
      this.caution.date=this.cautionToEdit.dateCaution
      this.caution.montant=this.cautionToEdit.montant
      this.caution.type=this.cautionToEdit.type
      this.typeCaution=this.cautionToEdit.type
      this.etatCaution =this.cautionToEdit.etat
      this.caution.etat=this.cautionToEdit.etat
    }
    //await this.getTypesCautions()
  }

};
</script>
<style scoped>
 .disabled {
    pointer-events:none;
    color: #bfcbd9;
    cursor: not-allowed;
    background-image: none;
    background-color: #eef1f6;
    border-color: #d1dbe5;   
 }
</style>
