const dayjs = require('dayjs');
const php = require('phpjs');


/**
 * Calcul et renvoi la consommation d'un indexe
 */
export function calcul_consommation_indexe(occupation, indexe, type) {
    const mode = { eau: occupation.modeEau, energie: occupation.modeEnergie },
        pu = { eau: occupation.puEau, energie: occupation.puEnergie }

    if (indexe.observation.toLowerCase() == 'complet') {
        return 0
    }
    if (mode.eau == 'index') {
        pu.eau = indexe.parametre.puEau
    }
    if (mode.energie == 'index') {
        pu.energie = indexe.parametre.puEnergie
    }
        
    const consommation = (mode[type] == 'index') ? (indexe.nouveau  - indexe.ancien) : 1

    return consommation * pu[type]
}

/**
 * calcule et renvoi la consommation mensuelle d'une occupation
 */
export function calcul_consommation(occupation, periode, type) {
    const indexes = occupation.indexes.filter(elt => elt.typeIndexe == type),
        indexeMois = getIndexesPeriode(periode, indexes)[0]

    if (!indexeMois) {
        return 0
    }
    
    return calcul_consommation_indexe(occupation, indexeMois, type)
}

export function getIndexesPeriode(periode, indexes) {
    return indexes.filter(e => dayjs(periode).diff(e.periode, 'month') == 0)
}

export function check_if_buy_loyer(occupation, periode) {
    
    const loyers = occupation.loyers.filter(e => dayjs(periode).diff(e.periode, 'month') == 0)
    if (loyers.length) {
        const totalPayer = loyers.reduce((accumulator, curr) => accumulator + curr.montantPayer, 0)
        if (totalPayer != 0) {
            if (totalPayer < occupation.loyerBase) {
                return [2, totalPayer]
            }
            return [1, totalPayer]
        }
    }
    return [0, 0]
}

export function check_if_buy_consommation(occupation, periode, type) {
    const consommation = calcul_consommation(occupation, periode, type)
    if (consommation == 0) {
        return [-1, consommation, 0]
    }
    const mode = { eau: occupation.modeEau, energie: occupation.modeEnergie}

    if (mode[type] == 'index') {
        const indexes = occupation.indexes.filter(elt => elt.typeIndexe == type)
        if (!php.empty(indexes)) {
            let indexeMois = getIndexesPeriode(periode, indexes)[0]
            if (!php.empty(indexeMois)) {
                if (consommation > indexeMois.avance) {
                    return [2, consommation, indexeMois.avance || 0]
                }
                if (consommation <= indexeMois.avance) {
                    return [1, consommation, indexeMois.avance || 0]
                }   
            }
        }
    }
    else if (mode[type] == 'forfait') {
        const charges = occupation.charges.filter(elt => elt.typeCharge.libelle.toLowerCase() == type || (elt.typeCharge.libelle.toLowerCase() == 'electricite' && type == 'energie')).filter(e => dayjs(periode).diff(dayjs(e.periode).format('YYYY-MM')+'-01', 'month') == 0)
        const totalCharge = charges.reduce((accumulator, curr) => accumulator + curr.montant, 0)

        if (charges.length) {
            const totalPayer = charges.reduce((accumulator, curr) => accumulator + curr.montantPayer, 0)
            if (totalPayer != 0) {
                if (totalPayer < totalCharge) {
                    return [2, totalCharge, totalPayer]
                }
                return [1, totalCharge, totalPayer]
            }
        }
        return [0, totalCharge, 0]
    }

    return [0, consommation, 0]
}

/**
 * Verifie si les charge d'une occupation pour une periode ont étées payées
 * 
 * @param {Object} occupation 
 * @param {String} periode 
 * @returns {Array<any>}
 */
export function check_if_buy_charges(occupation, periode) {
    const charges = occupation.charges.filter(elt => !php.in_array(elt.typeCharge.libelle.toLowerCase(), ['eau', 'energie', 'electricite'])).filter(e => dayjs(periode).diff(dayjs(e.periode).format('YYYY-MM')+'-01', 'month') == 0)
    const totalCharge = charges.reduce((accumulator, curr) => accumulator + curr.montant, 0)

    if (charges.length) {
        const totalPayer = charges.reduce((accumulator, curr) => accumulator + curr.montantPayer, 0)
        if (totalPayer != 0) {
            if (totalPayer < totalCharge) {
                return [2, totalPayer, totalCharge]
            }
            return [1, totalPayer, totalCharge]
        }
    }
    return [0, 0, totalCharge]
}